import './testingRewardEras.scss';
import Icon from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as chevronRight } from '../../../assets/images/chevron-right.svg';
import { RewardErasTable } from '../../../common/RewardErasTable';
import { TESTING_ROUTE } from '../../../routes/constants';

export function TestingRewardEras(): JSX.Element {
  const { state } = useLocation() as { state: { pubkey: string } };

  return (
    <div className="testing-reward-eras-page">
      <Breadcrumb separator={<Icon component={chevronRight} />}>
        <Breadcrumb.Item>
          <Link to={TESTING_ROUTE}>Testing</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Reward Eras</Breadcrumb.Item>
      </Breadcrumb>
      <RewardErasTable pubkey={state.pubkey} hideEditCol testing />
    </div>
  );
}

import { Button, Form, Modal, Select } from 'antd';
import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';

import { useListCurrenciesQuery } from '../../apis/admin.api';
import { rewardApi } from '../../apis/reward.api';
import { useTypedDispatch, useTypedSelector } from '../../app/store';

import type { TestingTowerIdName } from './testingSlice';

const { Option } = Select;

export interface JoinFormValues {
  assetHash: string;
}

interface JoinFormProps {
  joinModalVisible: boolean;
  name: TestingTowerIdName;
  setJoinModalVisible: Dispatch<SetStateAction<boolean>>;
}

export function JoinFormModal({ joinModalVisible, name, setJoinModalVisible }: JoinFormProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const { data: currencyList } = useListCurrenciesQuery();
  const [joinForm] = Form.useForm<JoinFormValues>();
  const [isJoining, setIsJoining] = useState<boolean>(false);
  const confidentialAddress = useTypedSelector(({ testing }) => testing.wallets[name].confidentialAddress);

  const onJoinConfirm = async () => {
    try {
      setIsJoining(true);
      const values = await joinForm.validateFields();
      await dispatch(
        rewardApi.endpoints.join.initiate({
          info: { address: confidentialAddress ?? '', assetHash: values.assetHash },
          walletName: name,
        })
      ).unwrap();
    } catch (err) {
      console.error(err);
    } finally {
      setJoinModalVisible(false);
      setIsJoining(false);
    }
  };

  return (
    <Modal
      visible={joinModalVisible}
      title="Join"
      confirmLoading={isJoining}
      onOk={onJoinConfirm}
      onCancel={() => setJoinModalVisible(false)}
      footer={[
        <Button key="back" onClick={() => setJoinModalVisible(false)}>
          Cancel
        </Button>,
        <Button type="primary" key="ok" onClick={onJoinConfirm}>
          Join
        </Button>,
      ]}
    >
      <Form
        className="send-tx-container text-start"
        form={joinForm}
        layout="vertical"
        name="send-tx-form"
        validateTrigger="onBlur"
        initialValues={{ assetHash: Object.values(currencyList || {})[0] }}
      >
        <Form.Item label="Asset" name="assetHash" rules={[{ required: true }]}>
          <Select className="w-100 mb-4">
            {Object.entries(currencyList || {}).map(([ticker, assetID]) => (
              <Option key={assetID} value={assetID}>
                {`${ticker} - ${assetID}`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}

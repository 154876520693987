// Fix TypeError: Do not know how to serialize a BigInt

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface BigInt {
  /** Convert to BigInt to string form in JSON.stringify */
  toJSON: () => string;
}

// @ts-ignore
// eslint-disable-next-line no-extend-native,func-names
BigInt.prototype.toJSON = function () {
  return this.toString();
};

import { Button, Col, Form, Input, Row } from 'antd';

import { useInsertCurrencyMutation } from '../../apis/admin.api';

export interface InsertCurrencyFormValues {
  assetHash: string;
  currencySymbol: string;
}

export function InsertCurrencyForm(): JSX.Element {
  const [form] = Form.useForm<InsertCurrencyFormValues>();
  const [insertCurrency, { isLoading: insertCurrencyIsLoading }] = useInsertCurrencyMutation();

  const onSubmit = async () => {
    const { assetHash, currencySymbol } = await form.validateFields();
    const res = await insertCurrency({ assetHash, currencySymbol });
    if ('error' in res) return;
    form.resetFields();
  };

  return (
    <Form
      className="insert-currency-container"
      form={form}
      layout="vertical"
      name="insert-currency_form"
      onFinish={onSubmit}
      validateTrigger="onBlur"
    >
      <div className="insert-currency-content">
        <Form.Item label="Currency Symbol" name="currencySymbol" rules={[{ required: true }]}>
          <Input placeholder="EUR" />
        </Form.Item>
        <Form.Item label="Asset Hash" name="assetHash" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </div>
      <Row justify="end">
        <Col>
          <Button type="primary" loading={insertCurrencyIsLoading} htmlType="submit">
            Insert Currency
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

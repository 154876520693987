import { Col, Row, Table, Typography } from 'antd';
import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { rewardApi } from '../../apis/reward.api';
import { useTypedDispatch, useTypedSelector } from '../../app/store';
import { TESTING_REWARD_ERAS_ROUTE } from '../../routes/constants';

import { DepositAddressModal } from './DepositAddressModal';
import { JoinFormModal } from './JoinFormModal';
import type { TestingTowerID, TestingTowerIdName } from './testingSlice';
import { requestAuthenticationToken } from './testingSlice';

const { Text } = Typography;

interface TestingWalletsTableProps {
  setWalletName: Dispatch<SetStateAction<TestingTowerIdName>>;
  walletName: TestingTowerIdName;
}

export function TestingWalletsTable({ setWalletName, walletName }: TestingWalletsTableProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [joinModalVisible, setJoinModalVisible] = useState<boolean>(false);
  const [depositAddressModalVisible, setDepositAddressModalVisible] = useState<boolean>(false);
  const testingWallets = useTypedSelector(({ testing }) => testing.wallets);
  const TESTING_WALLETS_PAGE_SIZE = 5;

  const expandedRowRender = (record: TestingTowerID): JSX.Element => (
    <div>
      <Row className="mb-1">
        <Col>
          <Text strong>Confidential Address:</Text> <Text copyable>{record.confidentialAddress}</Text>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <Text strong>Public Key:</Text> <Text copyable>{record.publicKey?.toString('hex')}</Text>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <Text strong>Private Key:</Text> <Text copyable>{record.privateKey?.toString('hex')}</Text>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <Text strong>Blinding Private Key:</Text> <Text copyable>{record.blindingPrivateKey}</Text>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <Text strong>Signing Key WIF:</Text> <Text copyable>{record.signingKeyWIF}</Text>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col>
          <Text strong>Blinding Key WIF:</Text> <Text copyable>{record.blindingKeyWIF}</Text>
        </Col>
      </Row>
    </div>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Authenticated',
      dataIndex: 'authenticationToken',
      key: 'authenticated',
      render: (authenticationToken: string) =>
        authenticationToken?.length ? <span className="checkmark__green">✔️</span> : '❌',
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (_: any, record: TestingTowerID) => (
        <>
          <Typography.Link
            onClick={() => {
              setWalletName(record.name);
              setDepositAddressModalVisible(true);
            }}
            className="mr-4"
          >
            Deposit
          </Typography.Link>
          <Typography.Link
            onClick={() => dispatch(requestAuthenticationToken({ name: record.name }))}
            className="mr-4"
          >
            Authenticate
          </Typography.Link>
          <Typography.Link
            onClick={() => {
              setJoinModalVisible(true);
              setWalletName(record.name);
            }}
            className="mr-4"
          >
            Join
          </Typography.Link>
          <Typography.Link
            onClick={() =>
              dispatch(
                rewardApi.endpoints.unJoin.initiate({
                  walletName,
                })
              )
            }
            className="mr-4"
          >
            UnJoin
          </Typography.Link>
          <Typography.Link
            onClick={() =>
              navigate(TESTING_REWARD_ERAS_ROUTE, {
                state: { pubkey: record.publicKey?.toString('hex') },
              })
            }
            className="mr-4"
          >
            Reward Eras
          </Typography.Link>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={Object.values(testingWallets) || []}
        expandable={{ expandedRowRender }}
        pagination={
          Object.keys(testingWallets).length > TESTING_WALLETS_PAGE_SIZE && {
            total: Object.keys(testingWallets).length,
            pageSize: TESTING_WALLETS_PAGE_SIZE,
          }
        }
        rowKey="name"
      />
      <DepositAddressModal
        depositAddressModalVisible={depositAddressModalVisible}
        setDepositAddressModalVisible={setDepositAddressModalVisible}
        name={walletName}
      />
      <JoinFormModal
        joinModalVisible={joinModalVisible}
        setJoinModalVisible={setJoinModalVisible}
        name={walletName}
      />
    </>
  );
}

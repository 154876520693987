import { Input, Table, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useListIdentitiesQuery } from '../../../apis/admin.api';
import { IDENTITY_REWARD_ERAS_ROUTE } from '../../../routes/constants';

const { Title } = Typography;

export function IdentityPublicKeys(): JSX.Element {
  const { data: ids } = useListIdentitiesQuery();
  const [filterId, setFilterId] = useState<string>();
  const { Search } = Input;

  const onSearch = (value: string) => setFilterId(value);

  return (
    <>
      <Title level={2}>Public Keys</Title>
      <Search className="mt-4 mb-8" placeholder="public key" onSearch={onSearch} enterButton />
      <Table
        rowKey="publicKey"
        dataSource={ids?.filter((id) => !filterId || id.publicKey.toUpperCase() === filterId.toUpperCase())}
        columns={[
          {
            title: 'Public Key',
            dataIndex: 'publicKey',
            key: 'publicKey',
            render: (id) => (
              <Link to={IDENTITY_REWARD_ERAS_ROUTE} state={{ pubkey: id }}>
                {id}
              </Link>
            ),
          },
          {
            title: 'Referral',
            dataIndex: 'referral',
            key: 'referral',
          },
        ]}
      />
    </>
  );
}

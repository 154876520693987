import { Button, Col, Form, Input, notification, Row, Select } from 'antd';
import type { WalletInterface } from 'ldk';
import { address, greedyCoinSelector, Psbt, walletFromAddresses } from 'ldk';
import React, { useState } from 'react';
import * as ecc from 'tiny-secp256k1';

import { useListCurrenciesQuery } from '../../apis/admin.api';
import { rewardApi } from '../../apis/reward.api';
import { useTypedDispatch, useTypedSelector } from '../../app/store';

import type { TestingTowerIdName } from './testingSlice';
import { getIdentity } from './testingSlice';

const { Option } = Select;

export interface SendAndInsertTxFormValues {
  from: TestingTowerIdName;
  to: TestingTowerIdName;
  asset: string;
  amount: number;
}

interface SendAndInsertTxFormProps {
  walletName: TestingTowerIdName;
}

export function SendAndInsertTxForm({ walletName }: SendAndInsertTxFormProps): JSX.Element {
  const { data: currencyList } = useListCurrenciesQuery();
  const [form] = Form.useForm<SendAndInsertTxFormValues>();
  const [isSendingTx, setIsSendingTx] = useState<boolean>(false);
  const dispatch = useTypedDispatch();
  const explorerLiquidAPI = useTypedSelector(({ settings }) => settings.explorerLiquidAPI);

  const sendTx = async (
    amount: number,
    asset: string,
    fromWallet: WalletInterface,
    fromAddr: string,
    fromChangeAddr: string,
    toAddr: string
  ) => {
    const psetBase64 = fromWallet.sendTx(
      {
        value: amount,
        asset,
        address: toAddr,
      },
      greedyCoinSelector(),
      () => fromChangeAddr
    );
    const pset = Psbt.fromBase64(psetBase64);
    const blinding = {
      [address.toOutputScript(fromAddr).toString('hex')]: address
        .fromConfidential(fromAddr)
        .blindingKey.toString('hex'),
    };
    await dispatch(
      rewardApi.endpoints.insertTransaction.initiate({
        txInfo: { txid: pset.TX.getId(), blinding },
        walletName,
      })
    ).unwrap();
  };

  const onSubmit = async () => {
    try {
      setIsSendingTx(true);
      const values = await form.validateFields();
      console.log('values', values);
      const fromIdentity = await dispatch(getIdentity({ name: values.from })).unwrap();
      const fromAddress = await fromIdentity.getNextAddress();
      const fromChangeAddress = await fromIdentity.getNextChangeAddress();
      const fromWallet = await walletFromAddresses(ecc, [fromAddress], explorerLiquidAPI);
      const toIdentity = await dispatch(getIdentity({ name: values.to })).unwrap();
      const toAddress = await toIdentity.getNextAddress();
      await sendTx(
        values.amount,
        values.asset,
        fromWallet,
        fromAddress.confidentialAddress,
        fromChangeAddress.confidentialAddress,
        toAddress.confidentialAddress
      );
    } catch (err) {
      notification.error({ message: (err as Error).message, key: (err as Error).message });
    } finally {
      setIsSendingTx(false);
    }
  };

  return (
    <Form
      className="send-tx-container text-start"
      form={form}
      layout="vertical"
      name="send-tx-form"
      onFinish={onSubmit}
      validateTrigger="onBlur"
      initialValues={{ asset: Object.values(currencyList || {})?.[0] }}
    >
      <Form.Item label="From" name="from" rules={[{ required: true }]}>
        <Select className="w-100">
          {['alice', 'bob', 'carol', 'dave'].map((name) => (
            <Option key={name} value={name}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="To" name="to" rules={[{ required: true }]}>
        <Select className="w-100">
          {['alice', 'bob', 'carol', 'dave'].map((name) => (
            <Option key={name} value={name}>
              {name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Asset" name="asset" rules={[{ required: true }]}>
        <Select className="w-100 mb-4">
          {Object.entries(currencyList || {}).map(([ticker, assetID]) => (
            <Option key={assetID} value={assetID}>
              {`${ticker} - ${assetID}`}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Row justify="end" className="mt-4 mb-8">
        <Col>
          <Button type="primary" loading={isSendingTx} htmlType="submit">
            Transfer
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

import { Button, Col, Divider, Form, Modal, notification, Row, Typography } from 'antd';
import { useState } from 'react';

import { towerApi } from '../../apis/tower.api';
import { useTypedDispatch, useTypedSelector } from '../../app/store';
import type { DaemonFormValues } from '../../common/DaemonFormItems';
import { DaemonFormItems } from '../../common/DaemonFormItems';
import { resetTestingState } from '../TxsTesting/testingSlice';

import { AdvancedModeSwitch } from './AdvancedModeSwitch';
import { BuildInfo } from './BuildInfo';
import { ExplorersLiquidApiForm } from './ExplorersLiquidAPIForm';
import { ExplorersLiquidUIForm } from './ExplorersLiquidUIForm';
import { resetSettings, selectBaseUrl, setMacaroon, setTowerDaemonEndpoint } from './settingsSlice';

export function Settings(): JSX.Element {
  const dispatch = useTypedDispatch();
  const [form] = Form.useForm<DaemonFormValues>();
  const [isMacaroonHidden, setIsMacaroonHidden] = useState<boolean>(false);
  const towerDaemonEndpoint = useTypedSelector(selectBaseUrl);
  const [isResetModalVisible, setIsResetModalVisible] = useState(false);

  const { Title } = Typography;

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (values.towerDaemonEndpoint) dispatch(setTowerDaemonEndpoint(values.towerDaemonEndpoint));
      if (values.macaroon) dispatch(setMacaroon(values.macaroon));
      form.resetFields();
      setIsMacaroonHidden(false);
      notification.success({ message: 'Settings have been updated' });
    } catch (err) {
      notification.error({ message: 'Settings update failed' });
      console.error('Form validation failed:', err);
    }
  };

  const resetAllState = () => {
    dispatch(resetSettings());
    dispatch(resetTestingState());
    dispatch(towerApi.util.resetApiState());
  };

  return (
    <>
      <Title level={2}>Preferences</Title>
      <ExplorersLiquidApiForm />
      <ExplorersLiquidUIForm />
      <AdvancedModeSwitch />
      <Divider />
      <Form
        form={form}
        layout="vertical"
        name="settings_form"
        onFinish={onSubmit}
        validateTrigger="onBlur"
        initialValues={{ towerDaemonEndpoint }}
      >
        <div className="settings-content">
          <Title level={2}>Tower Daemon Connection Infos</Title>
          <DaemonFormItems
            disabled
            hideUpload
            form={form}
            isMacaroonHidden={isMacaroonHidden}
            setIsMacaroonHidden={setIsMacaroonHidden}
          />
        </div>
        <Row justify="end" className="mt-4">
          <Col>
            <Button type="primary" htmlType="submit" className="settings-form-button" disabled>
              Save
            </Button>
          </Col>
        </Row>
        {/**/}
        <Divider />
        <BuildInfo />
        <Divider />
        <Title level={2}>Danger Zone</Title>
        <Button danger onClick={() => setIsResetModalVisible(true)}>
          Reset
        </Button>
        <Modal
          visible={isResetModalVisible}
          title="Erase all application data"
          okText="Confirm"
          cancelText="Cancel"
          onCancel={() => setIsResetModalVisible(false)}
          onOk={resetAllState}
        >
          <p>Are you sure that you want to erase all application data?</p>
        </Modal>
      </Form>
    </>
  );
}

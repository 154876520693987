/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter add_pb_suffix,eslint_disable,ts_nocheck
// @generated from protobuf file "tower/v1/admin.proto" (package "tower.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message tower.v1.BuildInfo
 */
export interface BuildInfo {
    /**
     * Version
     *
     * @generated from protobuf field: string version = 1;
     */
    version: string;
    /**
     * Commit
     *
     * @generated from protobuf field: string commit = 2;
     */
    commit: string;
    /**
     * Date
     *
     * @generated from protobuf field: string date = 3;
     */
    date: string;
}
/**
 * @generated from protobuf message tower.v1.GetInfoRequest
 */
export interface GetInfoRequest {
}
/**
 * @generated from protobuf message tower.v1.GetInfoResponse
 */
export interface GetInfoResponse {
    /**
     * @generated from protobuf field: bool has_currencies = 1;
     */
    hasCurrencies: boolean;
    /**
     * @generated from protobuf field: bool reward_era_active = 2;
     */
    rewardEraActive: boolean;
    /**
     * @generated from protobuf field: tower.v1.BuildInfo build_info = 3;
     */
    buildInfo?: BuildInfo;
}
/**
 * @generated from protobuf message tower.v1.CalculateRewardDistributionRequest
 */
export interface CalculateRewardDistributionRequest {
}
/**
 * @generated from protobuf message tower.v1.CalculateRewardDistributionResponse
 */
export interface CalculateRewardDistributionResponse {
}
/**
 * @generated from protobuf message tower.v1.IdentityInfo
 */
export interface IdentityInfo {
    /**
     * the reward era
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
    /**
     * EC pubkey of the identity we are requesting infos
     *
     * @generated from protobuf field: string public_key = 2;
     */
    publicKey: string;
}
/**
 * @generated from protobuf message tower.v1.InsertCurrencyRequest
 */
export interface InsertCurrencyRequest {
    /**
     * @generated from protobuf field: string currency_symbol = 1;
     */
    currencySymbol: string;
    /**
     * @generated from protobuf field: string asset_hash = 2;
     */
    assetHash: string; // it coul be empty
}
/**
 * @generated from protobuf message tower.v1.InsertCurrencyResponse
 */
export interface InsertCurrencyResponse {
}
/**
 * @generated from protobuf message tower.v1.Timeframe
 */
export interface Timeframe {
    /**
     * start block height
     *
     * @generated from protobuf field: uint64 start_block_height = 1 [jstype = JS_NUMBER];
     */
    startBlockHeight: number;
    /**
     * end block height
     *
     * @generated from protobuf field: uint64 end_block_height = 2 [jstype = JS_NUMBER];
     */
    endBlockHeight: number;
}
/**
 * @generated from protobuf message tower.v1.InsertRewardEraRequest
 */
export interface InsertRewardEraRequest {
    /**
     * reward_era if provided will be used to update existing reward era
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
    /**
     *  the amount of the reward fund for the current reward for a currency symbol
     *
     * @generated from protobuf field: map<string, double> reward_fund = 2;
     */
    rewardFund: {
        [key: string]: number;
    };
    /**
     * the timeframe expressed in block height
     *
     * @generated from protobuf field: tower.v1.Timeframe timeframe = 3;
     */
    timeframe?: Timeframe;
}
/**
 * @generated from protobuf message tower.v1.InsertRewardEraResponse
 */
export interface InsertRewardEraResponse {
}
/**
 * the identity with extra metadata
 *
 * @generated from protobuf message tower.v1.Identity
 */
export interface Identity {
    /**
     * @generated from protobuf field: string public_key = 1;
     */
    publicKey: string;
    /**
     * referral reference
     *
     * @generated from protobuf field: string referral = 2;
     */
    referral: string;
}
/**
 * @generated from protobuf message tower.v1.ListIdentitiesRequest
 */
export interface ListIdentitiesRequest {
}
/**
 * @generated from protobuf message tower.v1.ListIdentitiesResponse
 */
export interface ListIdentitiesResponse {
    /**
     * to be deprecated
     *
     * @generated from protobuf field: repeated string pubkeys = 1;
     */
    pubkeys: string[];
    /**
     * @generated from protobuf field: repeated tower.v1.Identity identities = 2;
     */
    identities: Identity[];
}
/**
 * @generated from protobuf message tower.v1.Payment
 */
export interface Payment {
    /**
     * @generated from protobuf field: tower.v1.Payment.Type type = 1;
     */
    type: Payment_Type;
    /**
     * @generated from protobuf field: map<string, double> amount_by_currency = 2;
     */
    amountByCurrency: {
        [key: string]: number;
    };
    /**
     * @generated from protobuf field: uint64 timestamp = 3;
     */
    timestamp: bigint;
    /**
     * @generated from protobuf field: string tx_hash = 4;
     */
    txHash: string;
}
/**
 * @generated from protobuf enum tower.v1.Payment.Type
 */
export enum Payment_Type {
    /**
     * @generated from protobuf enum value: TYPE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: TYPE_INBOUND = 1;
     */
    INBOUND = 1,
    /**
     * @generated from protobuf enum value: TYPE_OUTBOUND = 2;
     */
    OUTBOUND = 2
}
/**
 * @generated from protobuf message tower.v1.ListPaymentsRequest
 */
export interface ListPaymentsRequest {
    /**
     * @generated from protobuf field: tower.v1.IdentityInfo identity = 1;
     */
    identity?: IdentityInfo;
}
/**
 * @generated from protobuf message tower.v1.ListPaymentsResponse
 */
export interface ListPaymentsResponse {
    /**
     * List of Payment
     *
     * @generated from protobuf field: repeated tower.v1.Payment payments = 1;
     */
    payments: Payment[];
}
/**
 * @generated from protobuf message tower.v1.AdminUsageRequest
 */
export interface AdminUsageRequest {
    /**
     * @generated from protobuf field: tower.v1.IdentityInfo identity = 1;
     */
    identity?: IdentityInfo;
}
/**
 * @generated from protobuf message tower.v1.AdminUsageResponse
 */
export interface AdminUsageResponse {
    /**
     * the sum in satoshis of inbound and outbound transactions' value for a given asset hash
     *
     * @generated from protobuf field: map<string, double> usage = 1;
     */
    usage: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message tower.v1.AdminAverageBalanceRequest
 */
export interface AdminAverageBalanceRequest {
    /**
     * @generated from protobuf field: tower.v1.IdentityInfo identity = 1;
     */
    identity?: IdentityInfo;
}
/**
 * @generated from protobuf message tower.v1.AdminAverageBalanceResponse
 */
export interface AdminAverageBalanceResponse {
    /**
     * average balance in satoshis for a given asset hash
     *
     * @generated from protobuf field: map<string, double> average_balance = 1;
     */
    averageBalance: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message tower.v1.RewardDistributionRequest
 */
export interface RewardDistributionRequest {
    /**
     * @generated from protobuf field: tower.v1.IdentityInfo identity = 1;
     */
    identity?: IdentityInfo;
}
/**
 * @generated from protobuf message tower.v1.RewardDistributionResponse
 */
export interface RewardDistributionResponse {
    /**
     * reward amounts in satoshis to be distributed for a given currency symbol
     *
     * @generated from protobuf field: map<string, double> reward_distribution = 1;
     */
    rewardDistribution: {
        [key: string]: number;
    };
    /**
     * the result of dividing usage / avg. balance in percentage for a given currency symbol
     *
     * @generated from protobuf field: map<string, double> turnover = 2;
     */
    turnover: {
        [key: string]: number;
    };
    /**
     * the sum of the avg. balance and LRB of the previus user in the balance rank, for a given currency symbol
     *
     * @generated from protobuf field: map<string, tower.v1.LowRankedBalanceInfo> low_ranked_balance = 3;
     */
    lowRankedBalance: {
        [key: string]: LowRankedBalanceInfo;
    };
    /**
     * treshold of 20% based on the identity LRB and the previous user in the rank
     *
     * @generated from protobuf field: double turnover_threshold = 4;
     */
    turnoverThreshold: number;
    /**
     * the balance when greater than the previous reward era biggest turnover threshold for a given currency symbol
     *
     * @generated from protobuf field: map<string, tower.v1.QualifiedBalanceInfo> qualified_balance = 5;
     */
    qualifiedBalance: {
        [key: string]: QualifiedBalanceInfo;
    };
}
/**
 * @generated from protobuf message tower.v1.LowRankedBalanceInfo
 */
export interface LowRankedBalanceInfo {
    /**
     * @generated from protobuf field: double balance = 1;
     */
    balance: number;
    /**
     * @generated from protobuf field: double percentage = 2;
     */
    percentage: number;
}
/**
 * @generated from protobuf message tower.v1.QualifiedBalanceInfo
 */
export interface QualifiedBalanceInfo {
    /**
     * @generated from protobuf field: double balance = 1;
     */
    balance: number;
    /**
     * @generated from protobuf field: double percentage = 2;
     */
    percentage: number;
}
/**
 * @generated from protobuf message tower.v1.ListCurrenciesRequest
 */
export interface ListCurrenciesRequest {
}
/**
 * @generated from protobuf message tower.v1.ListCurrenciesResponse
 */
export interface ListCurrenciesResponse {
    /**
     * currency, asset_hash pairs
     *
     * @generated from protobuf field: map<string, string> currency_asset_pair = 1;
     */
    currencyAssetPair: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message tower.v1.ListRewardErasRequest
 */
export interface ListRewardErasRequest {
}
/**
 * @generated from protobuf message tower.v1.ListRewardErasResponse
 */
export interface ListRewardErasResponse {
    /**
     * List of RewardEra's
     *
     * @generated from protobuf field: repeated tower.v1.RewardEra reward_eras = 1;
     */
    rewardEras: RewardEra[];
}
/**
 * @generated from protobuf message tower.v1.RewardEra
 */
export interface RewardEra {
    /**
     * @generated from protobuf field: uint64 reward_era_id = 1 [jstype = JS_NUMBER];
     */
    rewardEraId: number;
    /**
     * @generated from protobuf field: uint64 start_block_height = 2 [jstype = JS_NUMBER];
     */
    startBlockHeight: number;
    /**
     * @generated from protobuf field: uint64 end_block_height = 3 [jstype = JS_NUMBER];
     */
    endBlockHeight: number;
    /**
     * @generated from protobuf field: bool processed = 4;
     */
    processed: boolean;
    /**
     * @generated from protobuf field: int32 turnover_threshold = 5;
     */
    turnoverThreshold: number;
    /**
     * @generated from protobuf field: tower.v1.FundInfo fund = 6;
     */
    fund?: FundInfo;
    /**
     * @generated from protobuf field: bool active = 7;
     */
    active: boolean;
}
/**
 * @generated from protobuf message tower.v1.FundInfo
 */
export interface FundInfo {
    /**
     * currency, amount pairs
     *
     * @generated from protobuf field: map<string, double> currency_amount_pair = 1;
     */
    currencyAmountPair: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message tower.v1.RevokeTokenRequest
 */
export interface RevokeTokenRequest {
    /**
     * EC public key identity for which token will be revoked
     *
     * @generated from protobuf field: string public_key = 1;
     */
    publicKey: string;
}
/**
 * @generated from protobuf message tower.v1.RevokeTokenResponse
 */
export interface RevokeTokenResponse {
}
/**
 * @generated from protobuf message tower.v1.RemoveCurrencyRequest
 */
export interface RemoveCurrencyRequest {
    /**
     * @generated from protobuf field: string currency_symbol = 1;
     */
    currencySymbol: string;
}
/**
 * @generated from protobuf message tower.v1.RemoveCurrencyResponse
 */
export interface RemoveCurrencyResponse {
}
/**
 * @generated from protobuf message tower.v1.RemoveRewardEraRequest
 */
export interface RemoveRewardEraRequest {
    /**
     * reward_era to delete
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
}
/**
 * @generated from protobuf message tower.v1.RemoveRewardEraResponse
 */
export interface RemoveRewardEraResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class BuildInfo$Type extends MessageType<BuildInfo> {
    constructor() {
        super("tower.v1.BuildInfo", [
            { no: 1, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "commit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BuildInfo>): BuildInfo {
        const message = { version: "", commit: "", date: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuildInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildInfo): BuildInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string version */ 1:
                    message.version = reader.string();
                    break;
                case /* string commit */ 2:
                    message.commit = reader.string();
                    break;
                case /* string date */ 3:
                    message.date = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string version = 1; */
        if (message.version !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.version);
        /* string commit = 2; */
        if (message.commit !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.commit);
        /* string date = 3; */
        if (message.date !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.date);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.BuildInfo
 */
export const BuildInfo = new BuildInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInfoRequest$Type extends MessageType<GetInfoRequest> {
    constructor() {
        super("tower.v1.GetInfoRequest", []);
    }
    create(value?: PartialMessage<GetInfoRequest>): GetInfoRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInfoRequest): GetInfoRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.GetInfoRequest
 */
export const GetInfoRequest = new GetInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInfoResponse$Type extends MessageType<GetInfoResponse> {
    constructor() {
        super("tower.v1.GetInfoResponse", [
            { no: 1, name: "has_currencies", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "reward_era_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "build_info", kind: "message", T: () => BuildInfo }
        ]);
    }
    create(value?: PartialMessage<GetInfoResponse>): GetInfoResponse {
        const message = { hasCurrencies: false, rewardEraActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInfoResponse): GetInfoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool has_currencies */ 1:
                    message.hasCurrencies = reader.bool();
                    break;
                case /* bool reward_era_active */ 2:
                    message.rewardEraActive = reader.bool();
                    break;
                case /* tower.v1.BuildInfo build_info */ 3:
                    message.buildInfo = BuildInfo.internalBinaryRead(reader, reader.uint32(), options, message.buildInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool has_currencies = 1; */
        if (message.hasCurrencies !== false)
            writer.tag(1, WireType.Varint).bool(message.hasCurrencies);
        /* bool reward_era_active = 2; */
        if (message.rewardEraActive !== false)
            writer.tag(2, WireType.Varint).bool(message.rewardEraActive);
        /* tower.v1.BuildInfo build_info = 3; */
        if (message.buildInfo)
            BuildInfo.internalBinaryWrite(message.buildInfo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.GetInfoResponse
 */
export const GetInfoResponse = new GetInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalculateRewardDistributionRequest$Type extends MessageType<CalculateRewardDistributionRequest> {
    constructor() {
        super("tower.v1.CalculateRewardDistributionRequest", []);
    }
    create(value?: PartialMessage<CalculateRewardDistributionRequest>): CalculateRewardDistributionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalculateRewardDistributionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalculateRewardDistributionRequest): CalculateRewardDistributionRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CalculateRewardDistributionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.CalculateRewardDistributionRequest
 */
export const CalculateRewardDistributionRequest = new CalculateRewardDistributionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalculateRewardDistributionResponse$Type extends MessageType<CalculateRewardDistributionResponse> {
    constructor() {
        super("tower.v1.CalculateRewardDistributionResponse", []);
    }
    create(value?: PartialMessage<CalculateRewardDistributionResponse>): CalculateRewardDistributionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CalculateRewardDistributionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalculateRewardDistributionResponse): CalculateRewardDistributionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CalculateRewardDistributionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.CalculateRewardDistributionResponse
 */
export const CalculateRewardDistributionResponse = new CalculateRewardDistributionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdentityInfo$Type extends MessageType<IdentityInfo> {
    constructor() {
        super("tower.v1.IdentityInfo", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "public_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IdentityInfo>): IdentityInfo {
        const message = { rewardEra: 0, publicKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IdentityInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IdentityInfo): IdentityInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                case /* string public_key */ 2:
                    message.publicKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IdentityInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        /* string public_key = 2; */
        if (message.publicKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.publicKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.IdentityInfo
 */
export const IdentityInfo = new IdentityInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertCurrencyRequest$Type extends MessageType<InsertCurrencyRequest> {
    constructor() {
        super("tower.v1.InsertCurrencyRequest", [
            { no: 1, name: "currency_symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "asset_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InsertCurrencyRequest>): InsertCurrencyRequest {
        const message = { currencySymbol: "", assetHash: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsertCurrencyRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertCurrencyRequest): InsertCurrencyRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string currency_symbol */ 1:
                    message.currencySymbol = reader.string();
                    break;
                case /* string asset_hash */ 2:
                    message.assetHash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InsertCurrencyRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string currency_symbol = 1; */
        if (message.currencySymbol !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.currencySymbol);
        /* string asset_hash = 2; */
        if (message.assetHash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.assetHash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.InsertCurrencyRequest
 */
export const InsertCurrencyRequest = new InsertCurrencyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertCurrencyResponse$Type extends MessageType<InsertCurrencyResponse> {
    constructor() {
        super("tower.v1.InsertCurrencyResponse", []);
    }
    create(value?: PartialMessage<InsertCurrencyResponse>): InsertCurrencyResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsertCurrencyResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertCurrencyResponse): InsertCurrencyResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: InsertCurrencyResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.InsertCurrencyResponse
 */
export const InsertCurrencyResponse = new InsertCurrencyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Timeframe$Type extends MessageType<Timeframe> {
    constructor() {
        super("tower.v1.Timeframe", [
            { no: 1, name: "start_block_height", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "end_block_height", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Timeframe>): Timeframe {
        const message = { startBlockHeight: 0, endBlockHeight: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Timeframe>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Timeframe): Timeframe {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 start_block_height = 1 [jstype = JS_NUMBER];*/ 1:
                    message.startBlockHeight = reader.uint64().toNumber();
                    break;
                case /* uint64 end_block_height = 2 [jstype = JS_NUMBER];*/ 2:
                    message.endBlockHeight = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Timeframe, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 start_block_height = 1 [jstype = JS_NUMBER]; */
        if (message.startBlockHeight !== 0)
            writer.tag(1, WireType.Varint).uint64(message.startBlockHeight);
        /* uint64 end_block_height = 2 [jstype = JS_NUMBER]; */
        if (message.endBlockHeight !== 0)
            writer.tag(2, WireType.Varint).uint64(message.endBlockHeight);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.Timeframe
 */
export const Timeframe = new Timeframe$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertRewardEraRequest$Type extends MessageType<InsertRewardEraRequest> {
    constructor() {
        super("tower.v1.InsertRewardEraRequest", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "reward_fund", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } },
            { no: 3, name: "timeframe", kind: "message", T: () => Timeframe }
        ]);
    }
    create(value?: PartialMessage<InsertRewardEraRequest>): InsertRewardEraRequest {
        const message = { rewardEra: 0, rewardFund: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsertRewardEraRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertRewardEraRequest): InsertRewardEraRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                case /* map<string, double> reward_fund */ 2:
                    this.binaryReadMap2(message.rewardFund, reader, options);
                    break;
                case /* tower.v1.Timeframe timeframe */ 3:
                    message.timeframe = Timeframe.internalBinaryRead(reader, reader.uint32(), options, message.timeframe);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: InsertRewardEraRequest["rewardFund"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof InsertRewardEraRequest["rewardFund"] | undefined, val: InsertRewardEraRequest["rewardFund"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.InsertRewardEraRequest.reward_fund");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: InsertRewardEraRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        /* map<string, double> reward_fund = 2; */
        for (let k of Object.keys(message.rewardFund))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.rewardFund[k]).join();
        /* tower.v1.Timeframe timeframe = 3; */
        if (message.timeframe)
            Timeframe.internalBinaryWrite(message.timeframe, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.InsertRewardEraRequest
 */
export const InsertRewardEraRequest = new InsertRewardEraRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertRewardEraResponse$Type extends MessageType<InsertRewardEraResponse> {
    constructor() {
        super("tower.v1.InsertRewardEraResponse", []);
    }
    create(value?: PartialMessage<InsertRewardEraResponse>): InsertRewardEraResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsertRewardEraResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertRewardEraResponse): InsertRewardEraResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: InsertRewardEraResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.InsertRewardEraResponse
 */
export const InsertRewardEraResponse = new InsertRewardEraResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Identity$Type extends MessageType<Identity> {
    constructor() {
        super("tower.v1.Identity", [
            { no: 1, name: "public_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "referral", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Identity>): Identity {
        const message = { publicKey: "", referral: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Identity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Identity): Identity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string public_key */ 1:
                    message.publicKey = reader.string();
                    break;
                case /* string referral */ 2:
                    message.referral = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Identity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string public_key = 1; */
        if (message.publicKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.publicKey);
        /* string referral = 2; */
        if (message.referral !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.referral);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.Identity
 */
export const Identity = new Identity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIdentitiesRequest$Type extends MessageType<ListIdentitiesRequest> {
    constructor() {
        super("tower.v1.ListIdentitiesRequest", []);
    }
    create(value?: PartialMessage<ListIdentitiesRequest>): ListIdentitiesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListIdentitiesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIdentitiesRequest): ListIdentitiesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListIdentitiesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListIdentitiesRequest
 */
export const ListIdentitiesRequest = new ListIdentitiesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIdentitiesResponse$Type extends MessageType<ListIdentitiesResponse> {
    constructor() {
        super("tower.v1.ListIdentitiesResponse", [
            { no: 1, name: "pubkeys", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "identities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Identity }
        ]);
    }
    create(value?: PartialMessage<ListIdentitiesResponse>): ListIdentitiesResponse {
        const message = { pubkeys: [], identities: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListIdentitiesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIdentitiesResponse): ListIdentitiesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string pubkeys */ 1:
                    message.pubkeys.push(reader.string());
                    break;
                case /* repeated tower.v1.Identity identities */ 2:
                    message.identities.push(Identity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListIdentitiesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string pubkeys = 1; */
        for (let i = 0; i < message.pubkeys.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.pubkeys[i]);
        /* repeated tower.v1.Identity identities = 2; */
        for (let i = 0; i < message.identities.length; i++)
            Identity.internalBinaryWrite(message.identities[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListIdentitiesResponse
 */
export const ListIdentitiesResponse = new ListIdentitiesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Payment$Type extends MessageType<Payment> {
    constructor() {
        super("tower.v1.Payment", [
            { no: 1, name: "type", kind: "enum", T: () => ["tower.v1.Payment.Type", Payment_Type, "TYPE_"] },
            { no: 2, name: "amount_by_currency", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } },
            { no: 3, name: "timestamp", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 4, name: "tx_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Payment>): Payment {
        const message = { type: 0, amountByCurrency: {}, timestamp: 0n, txHash: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Payment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Payment): Payment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tower.v1.Payment.Type type */ 1:
                    message.type = reader.int32();
                    break;
                case /* map<string, double> amount_by_currency */ 2:
                    this.binaryReadMap2(message.amountByCurrency, reader, options);
                    break;
                case /* uint64 timestamp */ 3:
                    message.timestamp = reader.uint64().toBigInt();
                    break;
                case /* string tx_hash */ 4:
                    message.txHash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: Payment["amountByCurrency"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Payment["amountByCurrency"] | undefined, val: Payment["amountByCurrency"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.Payment.amount_by_currency");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: Payment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tower.v1.Payment.Type type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* map<string, double> amount_by_currency = 2; */
        for (let k of Object.keys(message.amountByCurrency))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.amountByCurrency[k]).join();
        /* uint64 timestamp = 3; */
        if (message.timestamp !== 0n)
            writer.tag(3, WireType.Varint).uint64(message.timestamp);
        /* string tx_hash = 4; */
        if (message.txHash !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.txHash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.Payment
 */
export const Payment = new Payment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPaymentsRequest$Type extends MessageType<ListPaymentsRequest> {
    constructor() {
        super("tower.v1.ListPaymentsRequest", [
            { no: 1, name: "identity", kind: "message", T: () => IdentityInfo }
        ]);
    }
    create(value?: PartialMessage<ListPaymentsRequest>): ListPaymentsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListPaymentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPaymentsRequest): ListPaymentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tower.v1.IdentityInfo identity */ 1:
                    message.identity = IdentityInfo.internalBinaryRead(reader, reader.uint32(), options, message.identity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPaymentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tower.v1.IdentityInfo identity = 1; */
        if (message.identity)
            IdentityInfo.internalBinaryWrite(message.identity, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListPaymentsRequest
 */
export const ListPaymentsRequest = new ListPaymentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPaymentsResponse$Type extends MessageType<ListPaymentsResponse> {
    constructor() {
        super("tower.v1.ListPaymentsResponse", [
            { no: 1, name: "payments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Payment }
        ]);
    }
    create(value?: PartialMessage<ListPaymentsResponse>): ListPaymentsResponse {
        const message = { payments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListPaymentsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPaymentsResponse): ListPaymentsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tower.v1.Payment payments */ 1:
                    message.payments.push(Payment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPaymentsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tower.v1.Payment payments = 1; */
        for (let i = 0; i < message.payments.length; i++)
            Payment.internalBinaryWrite(message.payments[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListPaymentsResponse
 */
export const ListPaymentsResponse = new ListPaymentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminUsageRequest$Type extends MessageType<AdminUsageRequest> {
    constructor() {
        super("tower.v1.AdminUsageRequest", [
            { no: 1, name: "identity", kind: "message", T: () => IdentityInfo }
        ]);
    }
    create(value?: PartialMessage<AdminUsageRequest>): AdminUsageRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdminUsageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminUsageRequest): AdminUsageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tower.v1.IdentityInfo identity */ 1:
                    message.identity = IdentityInfo.internalBinaryRead(reader, reader.uint32(), options, message.identity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminUsageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tower.v1.IdentityInfo identity = 1; */
        if (message.identity)
            IdentityInfo.internalBinaryWrite(message.identity, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.AdminUsageRequest
 */
export const AdminUsageRequest = new AdminUsageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminUsageResponse$Type extends MessageType<AdminUsageResponse> {
    constructor() {
        super("tower.v1.AdminUsageResponse", [
            { no: 1, name: "usage", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } }
        ]);
    }
    create(value?: PartialMessage<AdminUsageResponse>): AdminUsageResponse {
        const message = { usage: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdminUsageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminUsageResponse): AdminUsageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, double> usage */ 1:
                    this.binaryReadMap1(message.usage, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: AdminUsageResponse["usage"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AdminUsageResponse["usage"] | undefined, val: AdminUsageResponse["usage"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.AdminUsageResponse.usage");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: AdminUsageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, double> usage = 1; */
        for (let k of Object.keys(message.usage))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.usage[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.AdminUsageResponse
 */
export const AdminUsageResponse = new AdminUsageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminAverageBalanceRequest$Type extends MessageType<AdminAverageBalanceRequest> {
    constructor() {
        super("tower.v1.AdminAverageBalanceRequest", [
            { no: 1, name: "identity", kind: "message", T: () => IdentityInfo }
        ]);
    }
    create(value?: PartialMessage<AdminAverageBalanceRequest>): AdminAverageBalanceRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdminAverageBalanceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminAverageBalanceRequest): AdminAverageBalanceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tower.v1.IdentityInfo identity */ 1:
                    message.identity = IdentityInfo.internalBinaryRead(reader, reader.uint32(), options, message.identity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminAverageBalanceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tower.v1.IdentityInfo identity = 1; */
        if (message.identity)
            IdentityInfo.internalBinaryWrite(message.identity, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.AdminAverageBalanceRequest
 */
export const AdminAverageBalanceRequest = new AdminAverageBalanceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminAverageBalanceResponse$Type extends MessageType<AdminAverageBalanceResponse> {
    constructor() {
        super("tower.v1.AdminAverageBalanceResponse", [
            { no: 1, name: "average_balance", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } }
        ]);
    }
    create(value?: PartialMessage<AdminAverageBalanceResponse>): AdminAverageBalanceResponse {
        const message = { averageBalance: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AdminAverageBalanceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminAverageBalanceResponse): AdminAverageBalanceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, double> average_balance */ 1:
                    this.binaryReadMap1(message.averageBalance, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: AdminAverageBalanceResponse["averageBalance"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AdminAverageBalanceResponse["averageBalance"] | undefined, val: AdminAverageBalanceResponse["averageBalance"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.AdminAverageBalanceResponse.average_balance");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: AdminAverageBalanceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, double> average_balance = 1; */
        for (let k of Object.keys(message.averageBalance))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.averageBalance[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.AdminAverageBalanceResponse
 */
export const AdminAverageBalanceResponse = new AdminAverageBalanceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RewardDistributionRequest$Type extends MessageType<RewardDistributionRequest> {
    constructor() {
        super("tower.v1.RewardDistributionRequest", [
            { no: 1, name: "identity", kind: "message", T: () => IdentityInfo }
        ]);
    }
    create(value?: PartialMessage<RewardDistributionRequest>): RewardDistributionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RewardDistributionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RewardDistributionRequest): RewardDistributionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tower.v1.IdentityInfo identity */ 1:
                    message.identity = IdentityInfo.internalBinaryRead(reader, reader.uint32(), options, message.identity);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RewardDistributionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tower.v1.IdentityInfo identity = 1; */
        if (message.identity)
            IdentityInfo.internalBinaryWrite(message.identity, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RewardDistributionRequest
 */
export const RewardDistributionRequest = new RewardDistributionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RewardDistributionResponse$Type extends MessageType<RewardDistributionResponse> {
    constructor() {
        super("tower.v1.RewardDistributionResponse", [
            { no: 1, name: "reward_distribution", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } },
            { no: 2, name: "turnover", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } },
            { no: 3, name: "low_ranked_balance", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => LowRankedBalanceInfo } },
            { no: 4, name: "turnover_threshold", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 5, name: "qualified_balance", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => QualifiedBalanceInfo } }
        ]);
    }
    create(value?: PartialMessage<RewardDistributionResponse>): RewardDistributionResponse {
        const message = { rewardDistribution: {}, turnover: {}, lowRankedBalance: {}, turnoverThreshold: 0, qualifiedBalance: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RewardDistributionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RewardDistributionResponse): RewardDistributionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, double> reward_distribution */ 1:
                    this.binaryReadMap1(message.rewardDistribution, reader, options);
                    break;
                case /* map<string, double> turnover */ 2:
                    this.binaryReadMap2(message.turnover, reader, options);
                    break;
                case /* map<string, tower.v1.LowRankedBalanceInfo> low_ranked_balance */ 3:
                    this.binaryReadMap3(message.lowRankedBalance, reader, options);
                    break;
                case /* double turnover_threshold */ 4:
                    message.turnoverThreshold = reader.double();
                    break;
                case /* map<string, tower.v1.QualifiedBalanceInfo> qualified_balance */ 5:
                    this.binaryReadMap5(message.qualifiedBalance, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: RewardDistributionResponse["rewardDistribution"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof RewardDistributionResponse["rewardDistribution"] | undefined, val: RewardDistributionResponse["rewardDistribution"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.RewardDistributionResponse.reward_distribution");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    private binaryReadMap2(map: RewardDistributionResponse["turnover"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof RewardDistributionResponse["turnover"] | undefined, val: RewardDistributionResponse["turnover"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.RewardDistributionResponse.turnover");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    private binaryReadMap3(map: RewardDistributionResponse["lowRankedBalance"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof RewardDistributionResponse["lowRankedBalance"] | undefined, val: RewardDistributionResponse["lowRankedBalance"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = LowRankedBalanceInfo.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.RewardDistributionResponse.low_ranked_balance");
            }
        }
        map[key ?? ""] = val ?? LowRankedBalanceInfo.create();
    }
    private binaryReadMap5(map: RewardDistributionResponse["qualifiedBalance"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof RewardDistributionResponse["qualifiedBalance"] | undefined, val: RewardDistributionResponse["qualifiedBalance"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = QualifiedBalanceInfo.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.RewardDistributionResponse.qualified_balance");
            }
        }
        map[key ?? ""] = val ?? QualifiedBalanceInfo.create();
    }
    internalBinaryWrite(message: RewardDistributionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, double> reward_distribution = 1; */
        for (let k of Object.keys(message.rewardDistribution))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.rewardDistribution[k]).join();
        /* map<string, double> turnover = 2; */
        for (let k of Object.keys(message.turnover))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.turnover[k]).join();
        /* map<string, tower.v1.LowRankedBalanceInfo> low_ranked_balance = 3; */
        for (let k of Object.keys(message.lowRankedBalance)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            LowRankedBalanceInfo.internalBinaryWrite(message.lowRankedBalance[k], writer, options);
            writer.join().join();
        }
        /* double turnover_threshold = 4; */
        if (message.turnoverThreshold !== 0)
            writer.tag(4, WireType.Bit64).double(message.turnoverThreshold);
        /* map<string, tower.v1.QualifiedBalanceInfo> qualified_balance = 5; */
        for (let k of Object.keys(message.qualifiedBalance)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            QualifiedBalanceInfo.internalBinaryWrite(message.qualifiedBalance[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RewardDistributionResponse
 */
export const RewardDistributionResponse = new RewardDistributionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LowRankedBalanceInfo$Type extends MessageType<LowRankedBalanceInfo> {
    constructor() {
        super("tower.v1.LowRankedBalanceInfo", [
            { no: 1, name: "balance", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "percentage", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<LowRankedBalanceInfo>): LowRankedBalanceInfo {
        const message = { balance: 0, percentage: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LowRankedBalanceInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LowRankedBalanceInfo): LowRankedBalanceInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double balance */ 1:
                    message.balance = reader.double();
                    break;
                case /* double percentage */ 2:
                    message.percentage = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LowRankedBalanceInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double balance = 1; */
        if (message.balance !== 0)
            writer.tag(1, WireType.Bit64).double(message.balance);
        /* double percentage = 2; */
        if (message.percentage !== 0)
            writer.tag(2, WireType.Bit64).double(message.percentage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.LowRankedBalanceInfo
 */
export const LowRankedBalanceInfo = new LowRankedBalanceInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QualifiedBalanceInfo$Type extends MessageType<QualifiedBalanceInfo> {
    constructor() {
        super("tower.v1.QualifiedBalanceInfo", [
            { no: 1, name: "balance", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "percentage", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<QualifiedBalanceInfo>): QualifiedBalanceInfo {
        const message = { balance: 0, percentage: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<QualifiedBalanceInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QualifiedBalanceInfo): QualifiedBalanceInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double balance */ 1:
                    message.balance = reader.double();
                    break;
                case /* double percentage */ 2:
                    message.percentage = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QualifiedBalanceInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double balance = 1; */
        if (message.balance !== 0)
            writer.tag(1, WireType.Bit64).double(message.balance);
        /* double percentage = 2; */
        if (message.percentage !== 0)
            writer.tag(2, WireType.Bit64).double(message.percentage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.QualifiedBalanceInfo
 */
export const QualifiedBalanceInfo = new QualifiedBalanceInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCurrenciesRequest$Type extends MessageType<ListCurrenciesRequest> {
    constructor() {
        super("tower.v1.ListCurrenciesRequest", []);
    }
    create(value?: PartialMessage<ListCurrenciesRequest>): ListCurrenciesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCurrenciesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCurrenciesRequest): ListCurrenciesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListCurrenciesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListCurrenciesRequest
 */
export const ListCurrenciesRequest = new ListCurrenciesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListCurrenciesResponse$Type extends MessageType<ListCurrenciesResponse> {
    constructor() {
        super("tower.v1.ListCurrenciesResponse", [
            { no: 1, name: "currency_asset_pair", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<ListCurrenciesResponse>): ListCurrenciesResponse {
        const message = { currencyAssetPair: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListCurrenciesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListCurrenciesResponse): ListCurrenciesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> currency_asset_pair */ 1:
                    this.binaryReadMap1(message.currencyAssetPair, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: ListCurrenciesResponse["currencyAssetPair"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ListCurrenciesResponse["currencyAssetPair"] | undefined, val: ListCurrenciesResponse["currencyAssetPair"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.ListCurrenciesResponse.currency_asset_pair");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: ListCurrenciesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> currency_asset_pair = 1; */
        for (let k of Object.keys(message.currencyAssetPair))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.currencyAssetPair[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListCurrenciesResponse
 */
export const ListCurrenciesResponse = new ListCurrenciesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRewardErasRequest$Type extends MessageType<ListRewardErasRequest> {
    constructor() {
        super("tower.v1.ListRewardErasRequest", []);
    }
    create(value?: PartialMessage<ListRewardErasRequest>): ListRewardErasRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListRewardErasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRewardErasRequest): ListRewardErasRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListRewardErasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListRewardErasRequest
 */
export const ListRewardErasRequest = new ListRewardErasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRewardErasResponse$Type extends MessageType<ListRewardErasResponse> {
    constructor() {
        super("tower.v1.ListRewardErasResponse", [
            { no: 1, name: "reward_eras", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RewardEra }
        ]);
    }
    create(value?: PartialMessage<ListRewardErasResponse>): ListRewardErasResponse {
        const message = { rewardEras: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListRewardErasResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRewardErasResponse): ListRewardErasResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tower.v1.RewardEra reward_eras */ 1:
                    message.rewardEras.push(RewardEra.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRewardErasResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tower.v1.RewardEra reward_eras = 1; */
        for (let i = 0; i < message.rewardEras.length; i++)
            RewardEra.internalBinaryWrite(message.rewardEras[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListRewardErasResponse
 */
export const ListRewardErasResponse = new ListRewardErasResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RewardEra$Type extends MessageType<RewardEra> {
    constructor() {
        super("tower.v1.RewardEra", [
            { no: 1, name: "reward_era_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "start_block_height", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "end_block_height", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "turnover_threshold", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "fund", kind: "message", T: () => FundInfo },
            { no: 7, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<RewardEra>): RewardEra {
        const message = { rewardEraId: 0, startBlockHeight: 0, endBlockHeight: 0, processed: false, turnoverThreshold: 0, active: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RewardEra>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RewardEra): RewardEra {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era_id = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEraId = reader.uint64().toNumber();
                    break;
                case /* uint64 start_block_height = 2 [jstype = JS_NUMBER];*/ 2:
                    message.startBlockHeight = reader.uint64().toNumber();
                    break;
                case /* uint64 end_block_height = 3 [jstype = JS_NUMBER];*/ 3:
                    message.endBlockHeight = reader.uint64().toNumber();
                    break;
                case /* bool processed */ 4:
                    message.processed = reader.bool();
                    break;
                case /* int32 turnover_threshold */ 5:
                    message.turnoverThreshold = reader.int32();
                    break;
                case /* tower.v1.FundInfo fund */ 6:
                    message.fund = FundInfo.internalBinaryRead(reader, reader.uint32(), options, message.fund);
                    break;
                case /* bool active */ 7:
                    message.active = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RewardEra, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era_id = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEraId !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEraId);
        /* uint64 start_block_height = 2 [jstype = JS_NUMBER]; */
        if (message.startBlockHeight !== 0)
            writer.tag(2, WireType.Varint).uint64(message.startBlockHeight);
        /* uint64 end_block_height = 3 [jstype = JS_NUMBER]; */
        if (message.endBlockHeight !== 0)
            writer.tag(3, WireType.Varint).uint64(message.endBlockHeight);
        /* bool processed = 4; */
        if (message.processed !== false)
            writer.tag(4, WireType.Varint).bool(message.processed);
        /* int32 turnover_threshold = 5; */
        if (message.turnoverThreshold !== 0)
            writer.tag(5, WireType.Varint).int32(message.turnoverThreshold);
        /* tower.v1.FundInfo fund = 6; */
        if (message.fund)
            FundInfo.internalBinaryWrite(message.fund, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bool active = 7; */
        if (message.active !== false)
            writer.tag(7, WireType.Varint).bool(message.active);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RewardEra
 */
export const RewardEra = new RewardEra$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FundInfo$Type extends MessageType<FundInfo> {
    constructor() {
        super("tower.v1.FundInfo", [
            { no: 1, name: "currency_amount_pair", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } }
        ]);
    }
    create(value?: PartialMessage<FundInfo>): FundInfo {
        const message = { currencyAmountPair: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FundInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FundInfo): FundInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, double> currency_amount_pair */ 1:
                    this.binaryReadMap1(message.currencyAmountPair, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: FundInfo["currencyAmountPair"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof FundInfo["currencyAmountPair"] | undefined, val: FundInfo["currencyAmountPair"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.FundInfo.currency_amount_pair");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: FundInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, double> currency_amount_pair = 1; */
        for (let k of Object.keys(message.currencyAmountPair))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.currencyAmountPair[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.FundInfo
 */
export const FundInfo = new FundInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RevokeTokenRequest$Type extends MessageType<RevokeTokenRequest> {
    constructor() {
        super("tower.v1.RevokeTokenRequest", [
            { no: 1, name: "public_key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RevokeTokenRequest>): RevokeTokenRequest {
        const message = { publicKey: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RevokeTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RevokeTokenRequest): RevokeTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string public_key */ 1:
                    message.publicKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RevokeTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string public_key = 1; */
        if (message.publicKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.publicKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RevokeTokenRequest
 */
export const RevokeTokenRequest = new RevokeTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RevokeTokenResponse$Type extends MessageType<RevokeTokenResponse> {
    constructor() {
        super("tower.v1.RevokeTokenResponse", []);
    }
    create(value?: PartialMessage<RevokeTokenResponse>): RevokeTokenResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RevokeTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RevokeTokenResponse): RevokeTokenResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RevokeTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RevokeTokenResponse
 */
export const RevokeTokenResponse = new RevokeTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveCurrencyRequest$Type extends MessageType<RemoveCurrencyRequest> {
    constructor() {
        super("tower.v1.RemoveCurrencyRequest", [
            { no: 1, name: "currency_symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveCurrencyRequest>): RemoveCurrencyRequest {
        const message = { currencySymbol: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveCurrencyRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveCurrencyRequest): RemoveCurrencyRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string currency_symbol */ 1:
                    message.currencySymbol = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveCurrencyRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string currency_symbol = 1; */
        if (message.currencySymbol !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.currencySymbol);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RemoveCurrencyRequest
 */
export const RemoveCurrencyRequest = new RemoveCurrencyRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveCurrencyResponse$Type extends MessageType<RemoveCurrencyResponse> {
    constructor() {
        super("tower.v1.RemoveCurrencyResponse", []);
    }
    create(value?: PartialMessage<RemoveCurrencyResponse>): RemoveCurrencyResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveCurrencyResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveCurrencyResponse): RemoveCurrencyResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveCurrencyResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RemoveCurrencyResponse
 */
export const RemoveCurrencyResponse = new RemoveCurrencyResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveRewardEraRequest$Type extends MessageType<RemoveRewardEraRequest> {
    constructor() {
        super("tower.v1.RemoveRewardEraRequest", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveRewardEraRequest>): RemoveRewardEraRequest {
        const message = { rewardEra: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveRewardEraRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveRewardEraRequest): RemoveRewardEraRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveRewardEraRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RemoveRewardEraRequest
 */
export const RemoveRewardEraRequest = new RemoveRewardEraRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveRewardEraResponse$Type extends MessageType<RemoveRewardEraResponse> {
    constructor() {
        super("tower.v1.RemoveRewardEraResponse", []);
    }
    create(value?: PartialMessage<RemoveRewardEraResponse>): RemoveRewardEraResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoveRewardEraResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveRewardEraResponse): RemoveRewardEraResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveRewardEraResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.RemoveRewardEraResponse
 */
export const RemoveRewardEraResponse = new RemoveRewardEraResponse$Type();
/**
 * @generated ServiceType for protobuf service tower.v1.AdminService
 */
export const AdminService = new ServiceType("tower.v1.AdminService", [
    { name: "GetInfo", options: { "google.api.http": { get: "/v1/admin/info" } }, I: GetInfoRequest, O: GetInfoResponse },
    { name: "InsertRewardEra", options: { "google.api.http": { post: "/v1/admin/era", body: "*" } }, I: InsertRewardEraRequest, O: InsertRewardEraResponse },
    { name: "RemoveRewardEra", options: { "google.api.http": { delete: "/v1/admin/era", body: "*" } }, I: RemoveRewardEraRequest, O: RemoveRewardEraResponse },
    { name: "InsertCurrency", options: { "google.api.http": { post: "/v1/admin/currency", body: "*" } }, I: InsertCurrencyRequest, O: InsertCurrencyResponse },
    { name: "RemoveCurrency", options: { "google.api.http": { delete: "/v1/admin/currency", body: "*" } }, I: RemoveCurrencyRequest, O: RemoveCurrencyResponse },
    { name: "ListIdentities", options: { "google.api.http": { get: "/v1/admin/identities" } }, I: ListIdentitiesRequest, O: ListIdentitiesResponse },
    { name: "ListPayments", options: { "google.api.http": { get: "/v1/admin/payments" } }, I: ListPaymentsRequest, O: ListPaymentsResponse },
    { name: "AdminUsage", options: { "google.api.http": { get: "/v1/admin/usage" } }, I: AdminUsageRequest, O: AdminUsageResponse },
    { name: "AdminAverageBalance", options: { "google.api.http": { get: "/v1/admin/average-balance" } }, I: AdminAverageBalanceRequest, O: AdminAverageBalanceResponse },
    { name: "RewardDistribution", options: { "google.api.http": { get: "/v1/admin/reward-distribution" } }, I: RewardDistributionRequest, O: RewardDistributionResponse },
    { name: "CalculateRewardDistribution", options: { "google.api.http": { post: "/v1/admin/job", body: "*" } }, I: CalculateRewardDistributionRequest, O: CalculateRewardDistributionResponse },
    { name: "ListCurrencies", options: { "google.api.http": { get: "/v1/admin/currencies" } }, I: ListCurrenciesRequest, O: ListCurrenciesResponse },
    { name: "ListRewardEras", options: { "google.api.http": { get: "/v1/admin/eras" } }, I: ListRewardErasRequest, O: ListRewardErasResponse },
    { name: "RevokeToken", options: { "google.api.http": { post: "/v1/admin/revoke", body: "*" } }, I: RevokeTokenRequest, O: RevokeTokenResponse }
]);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import * as bitcoinMessage from 'bitcoinjs-message';
import type { IdentityOpts, MnemonicOpts, PrivateKeyOpts } from 'ldk';
import { IdentityType, Mnemonic, PrivateKey } from 'ldk';
import * as ecc from 'tiny-secp256k1';

import type { AuthenticateResponse } from '../../api-spec/protobuf/gen/js/tower/v1/identity_pb';
import { identityApi } from '../../apis/identity.api';
import type { RootState } from '../../app/store';

export type TestingTowerIdName = 'alice' | 'bob' | 'carol' | 'dave';

export interface TestingTowerID {
  name: TestingTowerIdName;
  publicKey?: Buffer;
  privateKey?: Buffer;
  signingKeyWIF?: string;
  blindingKeyWIF?: string;
  blindingPrivateKey?: string;
  confidentialAddress?: string;
  authenticationToken?: string;
  authenticationSignature?: string;
}

export interface TestingState {
  mnemonic: string;
  wallets: Record<TestingTowerIdName, TestingTowerID>;
}

const initialState: TestingState = {
  // Mnemonic from which all Tower ids are generated
  mnemonic: 'turn manual grain tobacco pluck onion off chief drive amount slice forward',
  wallets: {
    alice: {
      name: 'alice',
    },
    bob: {
      name: 'bob',
    },
    carol: {
      name: 'carol',
    },
    dave: {
      name: 'dave',
    },
  },
};

export const setAllKeys = createAsyncThunk<
  {
    name: TestingTowerIdName;
    signingKeyWIF: string;
    blindingKeyWIF: string;
    blindingPrivateKey: string;
    publicKey: Buffer;
    privateKey: Buffer;
    confidentialAddress: string;
  }[],
  void,
  { state: RootState }
>('testing/setAllKeys', async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const mnemonicOpts: IdentityOpts<MnemonicOpts> = {
      chain: state.settings.network,
      type: IdentityType.Mnemonic,
      ecclib: ecc,
      opts: {
        mnemonic: state.testing.mnemonic,
      },
    };
    const mnemonicIdentity = new Mnemonic(mnemonicOpts);
    return Object.keys(state.testing.wallets).map((name, index) => ({
      name: name as TestingTowerIdName,
      signingKeyWIF: mnemonicIdentity.masterPrivateKeyNode.derive(index).toWIF(),
      blindingKeyWIF: mnemonicIdentity.masterPrivateKeyNode.derive(index).toWIF(),
      blindingPrivateKey: mnemonicIdentity.getAddress(false, index).address.blindingPrivateKey,
      privateKey: mnemonicIdentity.masterPrivateKeyNode.derive(index).privateKey ?? Buffer.from([]),
      publicKey: mnemonicIdentity.masterPrivateKeyNode.derive(index).publicKey,
      confidentialAddress: mnemonicIdentity.getAddress(false, index).address.confidentialAddress,
    }));
  } catch (err) {
    console.error('setAllKeys error', err);
    if (err instanceof TypeError) {
      return thunkAPI.rejectWithValue(err.message);
    }
    // @ts-ignore
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const getIdentity = createAsyncThunk<PrivateKey, { name: TestingTowerIdName }, { state: RootState }>(
  'testing/getIdentity',
  async ({ name }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const opts: IdentityOpts<PrivateKeyOpts> = {
        chain: state.settings.network,
        type: IdentityType.PrivateKey,
        ecclib: ecc,
        opts: {
          signingKeyWIF: state.testing.wallets[name].signingKeyWIF ?? '',
          blindingKeyWIF: state.testing.wallets[name].blindingKeyWIF ?? '',
        },
      };
      return new PrivateKey(opts);
    } catch (err) {
      console.error('getIdentity error', err);
      if (err instanceof TypeError) {
        return thunkAPI.rejectWithValue(err.message);
      }
      // @ts-ignore
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const requestAuthenticationToken = createAsyncThunk<
  {
    authenticationToken: AuthenticateResponse['token'];
    authenticationSignature: Buffer;
    name: TestingTowerIdName;
  },
  { name: TestingTowerIdName },
  { state: RootState }
>('settings/requestAuthenticationToken', async ({ name }, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const challenge = await thunkAPI.dispatch(identityApi.endpoints.challenge.initiate()).unwrap();
    const signature = bitcoinMessage.sign(
      // challenge needs to be casted instead of serializing with toString()
      challenge.challenge as unknown as string,
      state.testing.wallets[name].privateKey ?? Buffer.from([]),
      true
    );
    const res = await thunkAPI
      .dispatch(
        identityApi.endpoints.authenticate.initiate({
          requestId: challenge.requestId,
          publicKey: new Uint8Array(state.testing.wallets[name].publicKey ?? Buffer.from([])),
          signature: new Uint8Array(signature.buffer),
          referral: 'tower-ui-testing',
        })
      )
      .unwrap();
    notification.success({ message: 'Authentication successful' });
    return { authenticationToken: res.token, authenticationSignature: signature, name };
  } catch (err) {
    console.error('requestAuthenticationToken error', err);
    if (err instanceof TypeError) {
      return thunkAPI.rejectWithValue(err.message);
    }
    // @ts-ignore
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const testingSlice = createSlice({
  name: 'testing',
  initialState,
  reducers: {
    resetTestingState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(setAllKeys.fulfilled, (state: TestingState, action) => {
      action.payload.forEach((o) => {
        state.wallets[o.name].signingKeyWIF = o.signingKeyWIF;
        state.wallets[o.name].blindingKeyWIF = o.blindingKeyWIF;
        state.wallets[o.name].blindingPrivateKey = o.blindingPrivateKey;
        state.wallets[o.name].publicKey = o.publicKey;
        state.wallets[o.name].privateKey = o.privateKey;
        state.wallets[o.name].confidentialAddress = o.confidentialAddress;
      });
    });
    builder.addCase(requestAuthenticationToken.fulfilled, (state, action) => {
      state.wallets[action.payload.name].authenticationToken = action.payload.authenticationToken;
      state.wallets[action.payload.name].authenticationSignature =
        action.payload.authenticationSignature.toString('hex');
    });
  },
});

export const { resetTestingState } = testingSlice.actions;

export default testingSlice.reducer;

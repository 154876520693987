import type { ReactElement } from 'react';
import { Navigate, Route, Routes as RoutesRR, useLocation } from 'react-router-dom';

import { useTypedSelector } from '../app/store';
import { Onboarding } from '../features/Onboarding';
import { TxsTesting } from '../features/TxsTesting';
import { TestIdentityInfo } from '../features/TxsTesting/TestIdentityInfo';
import { TestingRewardEras } from '../features/TxsTesting/TestingRewardEras';
import { Currency } from '../features/currency';
import { IdentityInfo } from '../features/identity/IdentityInfo';
import { IdentityPublicKeys } from '../features/identity/IdentityPublicKeys';
import { IdentityRewardEras } from '../features/identity/IdentityRewardEras';
import { RewardEras } from '../features/reward/eras';
import { Settings } from '../features/settings';

import {
  CURRENCY_ROUTE,
  IDENTITY_INFO_ROUTE,
  IDENTITY_PUBLIC_KEYS_ROUTE,
  IDENTITY_REWARD_ERAS_ROUTE,
  ONBOARDING_ROUTE,
  REWARD_ERA_ROUTE,
  SETTINGS_ROUTE,
  TESTING_IDENTITY_INFO_ROUTE,
  TESTING_REWARD_ERAS_ROUTE,
  TESTING_ROUTE,
} from './constants';

function PrivateRoute({ children }: { children: JSX.Element }): ReactElement {
  const towerDaemonEndpoint = useTypedSelector(({ settings }) => settings.towerDaemonEndpoint);
  const macaroon = useTypedSelector(({ settings }) => settings.macaroon);
  const location = useLocation();
  if (!(towerDaemonEndpoint && macaroon)) {
    return <Navigate to={ONBOARDING_ROUTE} state={{ from: location }} replace />;
  }
  return children;
}

export function Routes(): JSX.Element {
  const towerDaemonEndpoint = useTypedSelector(({ settings }) => settings.towerDaemonEndpoint);
  const macaroon = useTypedSelector(({ settings }) => settings.macaroon);
  const isOnboarded = towerDaemonEndpoint && macaroon;

  return (
    <RoutesRR>
      <Route
        path="/"
        element={isOnboarded ? <Navigate to={CURRENCY_ROUTE} /> : <Navigate to={ONBOARDING_ROUTE} />}
      />
      <Route path={ONBOARDING_ROUTE} element={<Onboarding />} />
      <Route
        path={CURRENCY_ROUTE}
        element={
          <PrivateRoute>
            <Currency />
          </PrivateRoute>
        }
      />
      <Route
        path={IDENTITY_PUBLIC_KEYS_ROUTE}
        element={
          <PrivateRoute>
            <IdentityPublicKeys />
          </PrivateRoute>
        }
      />
      <Route
        path={IDENTITY_REWARD_ERAS_ROUTE}
        element={
          <PrivateRoute>
            <IdentityRewardEras />
          </PrivateRoute>
        }
      />
      <Route
        path={IDENTITY_INFO_ROUTE}
        element={
          <PrivateRoute>
            <IdentityInfo />
          </PrivateRoute>
        }
      />
      <Route
        path={REWARD_ERA_ROUTE}
        element={
          <PrivateRoute>
            <RewardEras />
          </PrivateRoute>
        }
      />
      <Route
        path={TESTING_ROUTE}
        element={
          <PrivateRoute>
            <TxsTesting />
          </PrivateRoute>
        }
      />
      <Route
        path={TESTING_REWARD_ERAS_ROUTE}
        element={
          <PrivateRoute>
            <TestingRewardEras />
          </PrivateRoute>
        }
      />
      <Route
        path={TESTING_IDENTITY_INFO_ROUTE}
        element={
          <PrivateRoute>
            <TestIdentityInfo />
          </PrivateRoute>
        }
      />
      <Route
        path={SETTINGS_ROUTE}
        element={
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        }
      />
    </RoutesRR>
  );
}

import { Col, Row, Typography } from 'antd';
import { QRCodeSVG } from 'qrcode.react';

const { Paragraph } = Typography;

export function KeyQRCode({
  addr,
  justify,
}: {
  addr: string;
  justify: 'start' | 'center' | 'end';
}): JSX.Element {
  return (
    <>
      <Row justify={justify} className="text-center mt-4">
        <Col xs={14} sm={12} lg={10} xl={8}>
          <QRCodeSVG value={addr} className="w-100 h-100" />
        </Col>
      </Row>
      <Row justify={justify} className="text-center mt-2">
        <Col span={16}>
          <Paragraph copyable style={{ lineBreak: 'anywhere', fontSize: 'smaller' }}>
            {addr}
          </Paragraph>
        </Col>
      </Row>
    </>
  );
}

import Icon from '@ant-design/icons';
import { Popover, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { Payment_Type } from '../../api-spec/protobuf/gen/js/tower/v1/admin_pb';
import { useListPaymentsQuery } from '../../apis/admin.api';
import { useTypedSelector } from '../../app/store';
import { ReactComponent as inbound } from '../../assets/images/inbound.svg';
import { ReactComponent as outbound } from '../../assets/images/outbound.svg';
import { formatFiat } from '../../utils';

interface PaymentTableProps {
  state: { rewardEraId: number; pubkey: string };
}

export function PaymentTable({ state }: PaymentTableProps): JSX.Element {
  const { explorerLiquidUI } = useTypedSelector(({ settings }) => settings);
  const PAYMENT_PAGE_SIZE = 4;
  const [paymentsFormatted, setPaymentsFormatted] = useState<
    {
      currency: JSX.Element[];
      amount: JSX.Element[];
      type: Payment_Type;
      date: string;
      txHash: string;
    }[]
  >([]);

  const { data: payments } = useListPaymentsQuery({
    identity: {
      rewardEra: state.rewardEraId,
      publicKey: state.pubkey,
    },
  });

  useEffect(() => {
    if (payments && payments.length > 0) {
      const newPayments = payments.map((payment) => {
        const currencies = Object.keys(payment.amountByCurrency)
          .sort()
          .map((currency) => <div key={currency}>{currency}</div>);
        const amounts = Object.entries(payment.amountByCurrency)
          .sort(([aCurrency], [bCurrency]) => {
            if (aCurrency > bCurrency) {
              return 1;
            }
            if (aCurrency < bCurrency) {
              return -1;
            }
            return 0;
          })
          .map(([, amount]) => <div key={amount}>{formatFiat(amount)}</div>);
        return {
          txHash: payment.txHash,
          type: payment.type,
          date: moment.unix(Number(payment.timestamp)).format('YYYY-MM-DD HH:mma'),
          currency: currencies,
          amount: amounts,
        };
      });
      setPaymentsFormatted(newPayments);
    }
  }, [payments]);

  return (
    <Table
      rowKey="txHash"
      columns={[
        {
          title: 'Transaction Hash',
          dataIndex: 'txHash',
          key: 'txHash',
          render: (txHash) => (
            <a target="_blank" href={`${explorerLiquidUI}/tx/${txHash}`} rel="noreferrer">
              {txHash}
            </a>
          ),
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          render: (type) =>
            type === 1 ? (
              <Popover trigger="hover" content={Payment_Type[type].toLowerCase()}>
                <Icon component={inbound} style={{ fontSize: '26px' }} />
              </Popover>
            ) : (
              <Popover trigger="hover" content={Payment_Type[type].toLowerCase()}>
                <Icon component={outbound} style={{ fontSize: '26px' }} />
              </Popover>
            ),
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          key: 'currency',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
        },
      ]}
      dataSource={paymentsFormatted || []}
      pagination={
        paymentsFormatted.length > PAYMENT_PAGE_SIZE && {
          total: paymentsFormatted.length,
          pageSize: PAYMENT_PAGE_SIZE,
        }
      }
    />
  );
}

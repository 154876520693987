import Icon from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { useAverageBalanceQuery, useUsageQuery } from '../../apis/reward.api';
import { useTypedSelector } from '../../app/store';
import { ReactComponent as chevronRight } from '../../assets/images/chevron-right.svg';
import { TESTING_REWARD_ERAS_ROUTE, TESTING_ROUTE } from '../../routes/constants';
import { CurrencyAmountTable } from '../identity/CurrencyAmountTable';

import type { TestingTowerIdName } from './testingSlice';

const { Title } = Typography;

export function TestIdentityInfo(): JSX.Element {
  const testingWallets = useTypedSelector(({ testing }) => testing.wallets);
  const { state } = useLocation() as {
    state: { pubkey: string; rewardEraId: number; walletName: TestingTowerIdName };
  };

  const walletName =
    Object.values(testingWallets).find((w) => w.publicKey?.toString('hex') === state.pubkey)?.name ?? 'alice';

  const { data: usage } = useUsageQuery({
    rewardEra: state.rewardEraId,
    walletName,
  });
  const { data: averageBalance } = useAverageBalanceQuery({
    rewardEra: state.rewardEraId,
    walletName,
  });

  return (
    <div className="identity-infos-page">
      <Breadcrumb separator={<Icon component={chevronRight} />}>
        <Breadcrumb.Item>
          <Link to={TESTING_ROUTE}>Testing</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={TESTING_REWARD_ERAS_ROUTE} state={{ pubkey: state.pubkey }}>
            Reward Eras
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Test Identities Info</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={2}>{`Reward ID #${state.rewardEraId}`}</Title>

      <Title level={2}>Usage</Title>
      <CurrencyAmountTable data={usage} />

      <Title level={2} className="mt-8">
        Average Balance
      </Title>
      <CurrencyAmountTable data={averageBalance} />
    </div>
  );
}

import { Button, Popconfirm, Table, Typography } from 'antd';

import { useListCurrenciesQuery, useRemoveCurrencyMutation } from '../../apis/admin.api';

import { InsertCurrencyForm } from './InsertCurrencyForm';

const { Title } = Typography;

export function Currency(): JSX.Element {
  const { data: currencyList } = useListCurrenciesQuery();
  const [removeCurrency] = useRemoveCurrencyMutation();

  const handleDelete = (currencySymbol: string) => {
    removeCurrency({ currencySymbol });
  };

  return (
    <>
      <Title level={2}>Insert Currency</Title>
      <InsertCurrencyForm />

      <Title level={2} className="mt-12">
        Currency List
      </Title>
      <Table
        rowKey="assetHash"
        dataSource={Object.entries(currencyList || {}).map(([currencySymbol, assetHash]) => ({
          currencySymbol,
          assetHash,
        }))}
        columns={[
          {
            title: 'Currency',
            dataIndex: 'currencySymbol',
            key: 'currencySymbol',
          },
          {
            title: 'Asset Hash',
            dataIndex: 'assetHash',
            key: 'assetHash',
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record: { currencySymbol: string; assetHash: string }) =>
              Object.keys(currencyList || {}).length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.currencySymbol)}>
                  <Button type="link">Delete</Button>
                </Popconfirm>
              ) : null,
          },
        ]}
      />
    </>
  );
}

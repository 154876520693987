import './onboarding.scss';

import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import { RpcError } from '@protobuf-ts/runtime-rpc';
import { Button, Col, Form, notification, Row, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ListCurrenciesRequest } from '../../api-spec/protobuf/gen/js/tower/v1/admin_pb';
import { AdminServiceClient } from '../../api-spec/protobuf/gen/js/tower/v1/admin_pb.client';
import { config } from '../../app/config';
import { useTypedDispatch, useTypedSelector } from '../../app/store';
import type { DaemonFormValues } from '../../common/DaemonFormItems';
import { DaemonFormItems } from '../../common/DaemonFormItems';
import { CURRENCY_ROUTE } from '../../routes/constants';
import { selectBaseUrl, setMacaroon, setTowerDaemonEndpoint } from '../settings/settingsSlice';

const { Title } = Typography;

export function Onboarding(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const towerDaemonEndpoint = useTypedSelector(selectBaseUrl);
  const [form] = Form.useForm<DaemonFormValues>();
  const [isMacaroonHidden, setIsMacaroonHidden] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      // Check if macaroon is valid
      const client = new AdminServiceClient(
        new GrpcWebFetchTransport({
          baseUrl: values.towerDaemonEndpoint,
        })
      );
      try {
        await client.listCurrencies(ListCurrenciesRequest.create(), {
          meta: { macaroon: values.macaroon },
        });
      } catch (err) {
        if (err instanceof RpcError && err.code !== 'NOT_FOUND') {
          throw err;
        }
      }
      dispatch(setTowerDaemonEndpoint(values.towerDaemonEndpoint));
      dispatch(setMacaroon(values.macaroon));
      // Enter app
      navigate(CURRENCY_ROUTE);
    } catch (err) {
      dispatch(setTowerDaemonEndpoint(config.towerDaemonEndpoint));
      dispatch(setMacaroon(''));
      notification.error({ message: (err as Error).message, key: 'error-onboarding' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      className="onboarding-container"
      form={form}
      layout="vertical"
      name="onboard_additional_infos_form"
      onFinish={onSubmit}
      validateTrigger="onBlur"
      initialValues={{ towerDaemonEndpoint }}
    >
      <div className="onboarding-content">
        <Title className="mb-10">Enter Your Connection Information</Title>
        <DaemonFormItems
          form={form}
          isMacaroonHidden={isMacaroonHidden}
          setIsMacaroonHidden={setIsMacaroonHidden}
        />
      </div>
      <Row justify="end">
        <Col>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Enter
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

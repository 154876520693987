import { DatePicker, Input, InputNumber } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useTypedSelector } from '../app/store';
import { convertBlockHeightToDate } from '../utils/esplora';

interface BlockPickerProps {
  setBlockHeight: (blockHeight: number | undefined) => void;
}

export function BlockPicker({ setBlockHeight }: BlockPickerProps): JSX.Element {
  const [futureBlockHeight, setFutureBlockHeight] = useState<number>();
  const [futureDate, setFutureDate] = useState<Moment | null>(null);
  const lastBlockHeight = useTypedSelector(({ settings }) => settings.lastBlockHeight);

  useEffect(() => {
    setBlockHeight(futureBlockHeight);
  }, [futureBlockHeight, setBlockHeight]);

  const onInputNumberStep = async (selectedBlock: number) => {
    if (selectedBlock === null) {
      setFutureBlockHeight(undefined);
      setFutureDate(null);
    } else {
      const date = convertBlockHeightToDate(lastBlockHeight, selectedBlock);
      setFutureBlockHeight(selectedBlock);
      setFutureDate(date);
    }
  };

  const onInputNumberChange = async (selectedBlock: number) => {
    if (selectedBlock === null) {
      setFutureBlockHeight(undefined);
      setFutureDate(null);
    } else {
      const date = convertBlockHeightToDate(lastBlockHeight, selectedBlock);
      setFutureBlockHeight(selectedBlock);
      setFutureDate(date);
    }
  };

  const handleDatePicker = async (selectedDate: Moment | null) => {
    try {
      setFutureDate(selectedDate);
      const minutes = selectedDate?.diff(moment(), 'minutes');
      setFutureBlockHeight(minutes !== undefined ? lastBlockHeight + minutes : undefined);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Input.Group compact>
      <InputNumber
        onChange={onInputNumberChange}
        onStep={onInputNumberStep}
        style={{ width: '30%' }}
        defaultValue={futureBlockHeight}
        value={futureBlockHeight}
      />
      <DatePicker
        showTime
        value={futureDate}
        disabledDate={(current) => current.isBefore(moment().subtract(1, 'day'))}
        onChange={handleDatePicker}
        onSelect={handleDatePicker}
        style={{ width: '70%' }}
        format="YYYY-MM-DD HH:mm"
      />
    </Input.Group>
  );
}

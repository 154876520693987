import { networks } from 'ldk';

import type { NetworkString } from '../features/settings/settingsSlice';

const getConfig = () => {
  switch (process.env.REACT_APP_CHAIN) {
    case 'testnet': {
      return {
        chain: 'testnet' as NetworkString,
        network: networks.testnet,
        towerDaemonEndpoint: 'https://stage-tower.equitas.foundation',
        explorerLiquidAPI: 'https://blockstream.info/liquidtestnet/api',
        explorerLiquidUI: 'https://blockstream.info/liquidtestnet',
      };
    }
    case 'regtest': {
      return {
        chain: 'regtest' as NetworkString,
        network: networks.regtest,
        towerDaemonEndpoint: 'http://localhost:9000',
        explorerLiquidAPI: 'http://localhost:3001',
        explorerLiquidUI: 'http://localhost:5001',
      };
    }
    default: {
      return {
        chain: 'liquid' as NetworkString,
        network: networks.liquid,
        towerDaemonEndpoint: 'https://tower.equitas.foundation',
        explorerLiquidAPI: 'https://blockstream.info/liquid/api',
        explorerLiquidUI: 'https://blockstream.info/liquid',
      };
    }
  }
};

const config = getConfig();
export { config };

import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Typography } from 'antd';
import { useState } from 'react';

import { RewardErasTable } from '../../../common/RewardErasTable';

import type { InsertRewardEraFormValues } from './InsertRewardEraForm';
import { InsertRewardEraForm } from './InsertRewardEraForm';

const { Title } = Typography;

export function RewardEras(): JSX.Element {
  const [showInsertRewardEraFormModal, setShowInsertRewardEraFormModal] = useState<boolean>(false);
  const [form] = Form.useForm<InsertRewardEraFormValues>();

  return (
    <>
      <Title level={2}>Reward Era</Title>
      <Row justify="end" className="mt-8 mb-4">
        <Col>
          <Button icon={<PlusOutlined />} onClick={() => setShowInsertRewardEraFormModal(true)}>
            Add Reward Era
          </Button>
        </Col>
      </Row>
      <RewardErasTable />
      <Modal
        style={{ textAlign: 'center' }}
        title="Insert Reward Era"
        visible={showInsertRewardEraFormModal}
        onCancel={() => {
          setShowInsertRewardEraFormModal(false);
          form.resetFields();
        }}
        footer={null}
      >
        <InsertRewardEraForm form={form} setShowInsertRewardEraFormModal={setShowInsertRewardEraFormModal} />
      </Modal>
    </>
  );
}

const rxLeadingZeros = /^[\s0]{2,}/;
const rxEndingZeros = /[\s0]+$/;
const removeInsignificant = (str: string): string => {
  const newStr = str.replace(rxLeadingZeros, '');
  const ary = newStr.split('.');
  if (ary.length > 1) {
    ary[1] = ary[1].replace(rxEndingZeros, '');
    if (ary[1].length === 0) {
      return ary[0];
    }
    return `${ary[0]}.${ary[1]}`;
  }
  return str;
};

export const formatFiat = (amount: number, precision = 8): string => {
  const amt = amount * 10 ** -precision;
  return removeInsignificant(amt.toFixed(8));
};

export const toSatoshi = (amount: number): number => amount * 10 ** 8;

/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter add_pb_suffix,eslint_disable,ts_nocheck
// @generated from protobuf file "tower/v1/reward.proto" (package "tower.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message tower.v1.UsageRequest
 */
export interface UsageRequest {
    /**
     * the reward era
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
}
/**
 * @generated from protobuf message tower.v1.UsageResponse
 */
export interface UsageResponse {
    /**
     * the sum in satoshis of inbound and outbound transactions' value for a given asset hash
     *
     * @generated from protobuf field: map<string, double> usage = 1;
     */
    usage: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message tower.v1.AverageBalanceRequest
 */
export interface AverageBalanceRequest {
    /**
     * the reward era
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
}
/**
 * @generated from protobuf message tower.v1.AverageBalanceResponse
 */
export interface AverageBalanceResponse {
    /**
     * average balance in satoshis for a given asset hash
     *
     * @generated from protobuf field: map<string, double> average_balance = 1;
     */
    averageBalance: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message tower.v1.PayoutInfo
 */
export interface PayoutInfo {
    /**
     * confidential address to be used as payout address of current and future reward eras
     *
     * @generated from protobuf field: string address = 1;
     */
    address: string;
    /**
     * asset hash to be used as payout asset receive rewards of current and future reward eras
     *
     * @generated from protobuf field: string asset_hash = 2;
     */
    assetHash: string;
}
/**
 * @generated from protobuf message tower.v1.JoinRequest
 */
export interface JoinRequest {
    /**
     * info about address and asset to be used for fut
     *
     * @generated from protobuf field: tower.v1.PayoutInfo info = 1;
     */
    info?: PayoutInfo;
}
/**
 * @generated from protobuf message tower.v1.JoinResponse
 */
export interface JoinResponse {
    /**
     * the progressive number of the era joined
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
    /**
     * the height of the starting block used for reward calculation
     *
     * @generated from protobuf field: uint64 block_height = 2 [jstype = JS_NUMBER];
     */
    blockHeight: number;
}
/**
 * @generated from protobuf message tower.v1.UnJoinRequest
 */
export interface UnJoinRequest {
}
/**
 * @generated from protobuf message tower.v1.UnJoinResponse
 */
export interface UnJoinResponse {
    /**
     * the progressive number of the era unjoined
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
}
/**
 * @generated from protobuf message tower.v1.UpdatePayoutInfoRequest
 */
export interface UpdatePayoutInfoRequest {
    /**
     * info about address and asset to be updated
     *
     * @generated from protobuf field: tower.v1.PayoutInfo info = 1;
     */
    info?: PayoutInfo;
}
/**
 * @generated from protobuf message tower.v1.UpdatePayoutInfoResponse
 */
export interface UpdatePayoutInfoResponse {
}
/**
 * @generated from protobuf message tower.v1.InsertTransactionRequest
 */
export interface InsertTransactionRequest {
    /**
     * @generated from protobuf field: tower.v1.TxInfo tx_info = 1;
     */
    txInfo?: TxInfo;
}
/**
 * @generated from protobuf message tower.v1.InsertTransactionResponse
 */
export interface InsertTransactionResponse {
    /**
     * This returns the progressive number of which the inserted transaction is going to be watched address will belong to
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
}
/**
 * @generated from protobuf message tower.v1.TxInfo
 */
export interface TxInfo {
    /**
     * transaction id of the broadcasted transaction
     *
     * @generated from protobuf field: string txid = 1;
     */
    txid: string;
    /**
     * map each output script to his own blinding private key as hex string
     * note that if client miss to send blinding for some input/output it will not be counted in reward process
     *
     * @generated from protobuf field: map<string, string> blinding = 2;
     */
    blinding: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message tower.v1.InsertTransactionsRequest
 */
export interface InsertTransactionsRequest {
    /**
     * @generated from protobuf field: repeated tower.v1.TxInfo tx_info = 1;
     */
    txInfo: TxInfo[];
}
/**
 * @generated from protobuf message tower.v1.InsertTransactionsResponse
 */
export interface InsertTransactionsResponse {
    /**
     * This returns the progressive number of which the inserted transaction is going to be watched address will belong to
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
}
/**
 * @generated from protobuf message tower.v1.FetchRewardRequest
 */
export interface FetchRewardRequest {
    /**
     * the reward era
     *
     * @generated from protobuf field: uint64 reward_era = 1 [jstype = JS_NUMBER];
     */
    rewardEra: number;
}
/**
 * @generated from protobuf message tower.v1.FetchRewardResponse
 */
export interface FetchRewardResponse {
    /**
     * amount in satoshis of asset to be paid as reward
     *
     * @generated from protobuf field: double amount = 1;
     */
    amount: number;
    /**
     * info about address and asset to receive
     *
     * @generated from protobuf field: tower.v1.PayoutInfo info = 2;
     */
    info?: PayoutInfo;
}
/**
 * @generated from protobuf message tower.v1.ListRewardCurrenciesRequest
 */
export interface ListRewardCurrenciesRequest {
}
/**
 * @generated from protobuf message tower.v1.ListRewardCurrenciesResponse
 */
export interface ListRewardCurrenciesResponse {
    /**
     * currency, asset_hash pairs
     *
     * @generated from protobuf field: map<string, string> currency_asset_pair = 1;
     */
    currencyAssetPair: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message tower.v1.ListErasRequest
 */
export interface ListErasRequest {
}
/**
 * @generated from protobuf message tower.v1.ListErasResponse
 */
export interface ListErasResponse {
    /**
     * List of RewardEra's
     *
     * @generated from protobuf field: repeated tower.v1.Era reward_eras = 1;
     */
    rewardEras: Era[];
}
/**
 * @generated from protobuf message tower.v1.Era
 */
export interface Era {
    /**
     * @generated from protobuf field: uint64 reward_era_id = 1 [jstype = JS_NUMBER];
     */
    rewardEraId: number;
    /**
     * @generated from protobuf field: uint64 start_block_height = 2 [jstype = JS_NUMBER];
     */
    startBlockHeight: number;
    /**
     * @generated from protobuf field: uint64 end_block_height = 3 [jstype = JS_NUMBER];
     */
    endBlockHeight: number;
    /**
     * @generated from protobuf field: bool active = 5;
     */
    active: boolean;
    /**
     * @generated from protobuf field: bool processed = 6;
     */
    processed: boolean;
}
// @generated message type with reflection information, may provide speed optimized methods
class UsageRequest$Type extends MessageType<UsageRequest> {
    constructor() {
        super("tower.v1.UsageRequest", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UsageRequest>): UsageRequest {
        const message = { rewardEra: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UsageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsageRequest): UsageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.UsageRequest
 */
export const UsageRequest = new UsageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsageResponse$Type extends MessageType<UsageResponse> {
    constructor() {
        super("tower.v1.UsageResponse", [
            { no: 1, name: "usage", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } }
        ]);
    }
    create(value?: PartialMessage<UsageResponse>): UsageResponse {
        const message = { usage: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UsageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsageResponse): UsageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, double> usage */ 1:
                    this.binaryReadMap1(message.usage, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: UsageResponse["usage"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof UsageResponse["usage"] | undefined, val: UsageResponse["usage"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.UsageResponse.usage");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: UsageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, double> usage = 1; */
        for (let k of Object.keys(message.usage))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.usage[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.UsageResponse
 */
export const UsageResponse = new UsageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AverageBalanceRequest$Type extends MessageType<AverageBalanceRequest> {
    constructor() {
        super("tower.v1.AverageBalanceRequest", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AverageBalanceRequest>): AverageBalanceRequest {
        const message = { rewardEra: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AverageBalanceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AverageBalanceRequest): AverageBalanceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AverageBalanceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.AverageBalanceRequest
 */
export const AverageBalanceRequest = new AverageBalanceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AverageBalanceResponse$Type extends MessageType<AverageBalanceResponse> {
    constructor() {
        super("tower.v1.AverageBalanceResponse", [
            { no: 1, name: "average_balance", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ } }
        ]);
    }
    create(value?: PartialMessage<AverageBalanceResponse>): AverageBalanceResponse {
        const message = { averageBalance: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AverageBalanceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AverageBalanceResponse): AverageBalanceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, double> average_balance */ 1:
                    this.binaryReadMap1(message.averageBalance, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: AverageBalanceResponse["averageBalance"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AverageBalanceResponse["averageBalance"] | undefined, val: AverageBalanceResponse["averageBalance"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.double();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.AverageBalanceResponse.average_balance");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: AverageBalanceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, double> average_balance = 1; */
        for (let k of Object.keys(message.averageBalance))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Bit64).double(message.averageBalance[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.AverageBalanceResponse
 */
export const AverageBalanceResponse = new AverageBalanceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PayoutInfo$Type extends MessageType<PayoutInfo> {
    constructor() {
        super("tower.v1.PayoutInfo", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "asset_hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PayoutInfo>): PayoutInfo {
        const message = { address: "", assetHash: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PayoutInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PayoutInfo): PayoutInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* string asset_hash */ 2:
                    message.assetHash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PayoutInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* string asset_hash = 2; */
        if (message.assetHash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.assetHash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.PayoutInfo
 */
export const PayoutInfo = new PayoutInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinRequest$Type extends MessageType<JoinRequest> {
    constructor() {
        super("tower.v1.JoinRequest", [
            { no: 1, name: "info", kind: "message", T: () => PayoutInfo }
        ]);
    }
    create(value?: PartialMessage<JoinRequest>): JoinRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JoinRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinRequest): JoinRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tower.v1.PayoutInfo info */ 1:
                    message.info = PayoutInfo.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JoinRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tower.v1.PayoutInfo info = 1; */
        if (message.info)
            PayoutInfo.internalBinaryWrite(message.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.JoinRequest
 */
export const JoinRequest = new JoinRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinResponse$Type extends MessageType<JoinResponse> {
    constructor() {
        super("tower.v1.JoinResponse", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "block_height", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<JoinResponse>): JoinResponse {
        const message = { rewardEra: 0, blockHeight: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JoinResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinResponse): JoinResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                case /* uint64 block_height = 2 [jstype = JS_NUMBER];*/ 2:
                    message.blockHeight = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JoinResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        /* uint64 block_height = 2 [jstype = JS_NUMBER]; */
        if (message.blockHeight !== 0)
            writer.tag(2, WireType.Varint).uint64(message.blockHeight);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.JoinResponse
 */
export const JoinResponse = new JoinResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnJoinRequest$Type extends MessageType<UnJoinRequest> {
    constructor() {
        super("tower.v1.UnJoinRequest", []);
    }
    create(value?: PartialMessage<UnJoinRequest>): UnJoinRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UnJoinRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnJoinRequest): UnJoinRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UnJoinRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.UnJoinRequest
 */
export const UnJoinRequest = new UnJoinRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnJoinResponse$Type extends MessageType<UnJoinResponse> {
    constructor() {
        super("tower.v1.UnJoinResponse", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UnJoinResponse>): UnJoinResponse {
        const message = { rewardEra: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UnJoinResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnJoinResponse): UnJoinResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnJoinResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.UnJoinResponse
 */
export const UnJoinResponse = new UnJoinResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePayoutInfoRequest$Type extends MessageType<UpdatePayoutInfoRequest> {
    constructor() {
        super("tower.v1.UpdatePayoutInfoRequest", [
            { no: 1, name: "info", kind: "message", T: () => PayoutInfo }
        ]);
    }
    create(value?: PartialMessage<UpdatePayoutInfoRequest>): UpdatePayoutInfoRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePayoutInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePayoutInfoRequest): UpdatePayoutInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tower.v1.PayoutInfo info */ 1:
                    message.info = PayoutInfo.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePayoutInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tower.v1.PayoutInfo info = 1; */
        if (message.info)
            PayoutInfo.internalBinaryWrite(message.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.UpdatePayoutInfoRequest
 */
export const UpdatePayoutInfoRequest = new UpdatePayoutInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePayoutInfoResponse$Type extends MessageType<UpdatePayoutInfoResponse> {
    constructor() {
        super("tower.v1.UpdatePayoutInfoResponse", []);
    }
    create(value?: PartialMessage<UpdatePayoutInfoResponse>): UpdatePayoutInfoResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePayoutInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePayoutInfoResponse): UpdatePayoutInfoResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdatePayoutInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.UpdatePayoutInfoResponse
 */
export const UpdatePayoutInfoResponse = new UpdatePayoutInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertTransactionRequest$Type extends MessageType<InsertTransactionRequest> {
    constructor() {
        super("tower.v1.InsertTransactionRequest", [
            { no: 1, name: "tx_info", kind: "message", T: () => TxInfo }
        ]);
    }
    create(value?: PartialMessage<InsertTransactionRequest>): InsertTransactionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsertTransactionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertTransactionRequest): InsertTransactionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* tower.v1.TxInfo tx_info */ 1:
                    message.txInfo = TxInfo.internalBinaryRead(reader, reader.uint32(), options, message.txInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InsertTransactionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* tower.v1.TxInfo tx_info = 1; */
        if (message.txInfo)
            TxInfo.internalBinaryWrite(message.txInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.InsertTransactionRequest
 */
export const InsertTransactionRequest = new InsertTransactionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertTransactionResponse$Type extends MessageType<InsertTransactionResponse> {
    constructor() {
        super("tower.v1.InsertTransactionResponse", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<InsertTransactionResponse>): InsertTransactionResponse {
        const message = { rewardEra: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsertTransactionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertTransactionResponse): InsertTransactionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InsertTransactionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.InsertTransactionResponse
 */
export const InsertTransactionResponse = new InsertTransactionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TxInfo$Type extends MessageType<TxInfo> {
    constructor() {
        super("tower.v1.TxInfo", [
            { no: 1, name: "txid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "blinding", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<TxInfo>): TxInfo {
        const message = { txid: "", blinding: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TxInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TxInfo): TxInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string txid */ 1:
                    message.txid = reader.string();
                    break;
                case /* map<string, string> blinding */ 2:
                    this.binaryReadMap2(message.blinding, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: TxInfo["blinding"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof TxInfo["blinding"] | undefined, val: TxInfo["blinding"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.TxInfo.blinding");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: TxInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string txid = 1; */
        if (message.txid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.txid);
        /* map<string, string> blinding = 2; */
        for (let k of Object.keys(message.blinding))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.blinding[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.TxInfo
 */
export const TxInfo = new TxInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertTransactionsRequest$Type extends MessageType<InsertTransactionsRequest> {
    constructor() {
        super("tower.v1.InsertTransactionsRequest", [
            { no: 1, name: "tx_info", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TxInfo }
        ]);
    }
    create(value?: PartialMessage<InsertTransactionsRequest>): InsertTransactionsRequest {
        const message = { txInfo: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsertTransactionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertTransactionsRequest): InsertTransactionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tower.v1.TxInfo tx_info */ 1:
                    message.txInfo.push(TxInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InsertTransactionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tower.v1.TxInfo tx_info = 1; */
        for (let i = 0; i < message.txInfo.length; i++)
            TxInfo.internalBinaryWrite(message.txInfo[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.InsertTransactionsRequest
 */
export const InsertTransactionsRequest = new InsertTransactionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InsertTransactionsResponse$Type extends MessageType<InsertTransactionsResponse> {
    constructor() {
        super("tower.v1.InsertTransactionsResponse", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<InsertTransactionsResponse>): InsertTransactionsResponse {
        const message = { rewardEra: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InsertTransactionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InsertTransactionsResponse): InsertTransactionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InsertTransactionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.InsertTransactionsResponse
 */
export const InsertTransactionsResponse = new InsertTransactionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FetchRewardRequest$Type extends MessageType<FetchRewardRequest> {
    constructor() {
        super("tower.v1.FetchRewardRequest", [
            { no: 1, name: "reward_era", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<FetchRewardRequest>): FetchRewardRequest {
        const message = { rewardEra: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FetchRewardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FetchRewardRequest): FetchRewardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEra = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FetchRewardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEra !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.FetchRewardRequest
 */
export const FetchRewardRequest = new FetchRewardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FetchRewardResponse$Type extends MessageType<FetchRewardResponse> {
    constructor() {
        super("tower.v1.FetchRewardResponse", [
            { no: 1, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "info", kind: "message", T: () => PayoutInfo }
        ]);
    }
    create(value?: PartialMessage<FetchRewardResponse>): FetchRewardResponse {
        const message = { amount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FetchRewardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FetchRewardResponse): FetchRewardResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double amount */ 1:
                    message.amount = reader.double();
                    break;
                case /* tower.v1.PayoutInfo info */ 2:
                    message.info = PayoutInfo.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FetchRewardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double amount = 1; */
        if (message.amount !== 0)
            writer.tag(1, WireType.Bit64).double(message.amount);
        /* tower.v1.PayoutInfo info = 2; */
        if (message.info)
            PayoutInfo.internalBinaryWrite(message.info, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.FetchRewardResponse
 */
export const FetchRewardResponse = new FetchRewardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRewardCurrenciesRequest$Type extends MessageType<ListRewardCurrenciesRequest> {
    constructor() {
        super("tower.v1.ListRewardCurrenciesRequest", []);
    }
    create(value?: PartialMessage<ListRewardCurrenciesRequest>): ListRewardCurrenciesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListRewardCurrenciesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRewardCurrenciesRequest): ListRewardCurrenciesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListRewardCurrenciesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListRewardCurrenciesRequest
 */
export const ListRewardCurrenciesRequest = new ListRewardCurrenciesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRewardCurrenciesResponse$Type extends MessageType<ListRewardCurrenciesResponse> {
    constructor() {
        super("tower.v1.ListRewardCurrenciesResponse", [
            { no: 1, name: "currency_asset_pair", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<ListRewardCurrenciesResponse>): ListRewardCurrenciesResponse {
        const message = { currencyAssetPair: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListRewardCurrenciesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRewardCurrenciesResponse): ListRewardCurrenciesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> currency_asset_pair */ 1:
                    this.binaryReadMap1(message.currencyAssetPair, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: ListRewardCurrenciesResponse["currencyAssetPair"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ListRewardCurrenciesResponse["currencyAssetPair"] | undefined, val: ListRewardCurrenciesResponse["currencyAssetPair"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field tower.v1.ListRewardCurrenciesResponse.currency_asset_pair");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: ListRewardCurrenciesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> currency_asset_pair = 1; */
        for (let k of Object.keys(message.currencyAssetPair))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.currencyAssetPair[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListRewardCurrenciesResponse
 */
export const ListRewardCurrenciesResponse = new ListRewardCurrenciesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListErasRequest$Type extends MessageType<ListErasRequest> {
    constructor() {
        super("tower.v1.ListErasRequest", []);
    }
    create(value?: PartialMessage<ListErasRequest>): ListErasRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListErasRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListErasRequest): ListErasRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListErasRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListErasRequest
 */
export const ListErasRequest = new ListErasRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListErasResponse$Type extends MessageType<ListErasResponse> {
    constructor() {
        super("tower.v1.ListErasResponse", [
            { no: 1, name: "reward_eras", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Era }
        ]);
    }
    create(value?: PartialMessage<ListErasResponse>): ListErasResponse {
        const message = { rewardEras: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListErasResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListErasResponse): ListErasResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated tower.v1.Era reward_eras */ 1:
                    message.rewardEras.push(Era.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListErasResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated tower.v1.Era reward_eras = 1; */
        for (let i = 0; i < message.rewardEras.length; i++)
            Era.internalBinaryWrite(message.rewardEras[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.ListErasResponse
 */
export const ListErasResponse = new ListErasResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Era$Type extends MessageType<Era> {
    constructor() {
        super("tower.v1.Era", [
            { no: 1, name: "reward_era_id", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "start_block_height", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "end_block_height", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "processed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Era>): Era {
        const message = { rewardEraId: 0, startBlockHeight: 0, endBlockHeight: 0, active: false, processed: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Era>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Era): Era {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 reward_era_id = 1 [jstype = JS_NUMBER];*/ 1:
                    message.rewardEraId = reader.uint64().toNumber();
                    break;
                case /* uint64 start_block_height = 2 [jstype = JS_NUMBER];*/ 2:
                    message.startBlockHeight = reader.uint64().toNumber();
                    break;
                case /* uint64 end_block_height = 3 [jstype = JS_NUMBER];*/ 3:
                    message.endBlockHeight = reader.uint64().toNumber();
                    break;
                case /* bool active */ 5:
                    message.active = reader.bool();
                    break;
                case /* bool processed */ 6:
                    message.processed = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Era, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 reward_era_id = 1 [jstype = JS_NUMBER]; */
        if (message.rewardEraId !== 0)
            writer.tag(1, WireType.Varint).uint64(message.rewardEraId);
        /* uint64 start_block_height = 2 [jstype = JS_NUMBER]; */
        if (message.startBlockHeight !== 0)
            writer.tag(2, WireType.Varint).uint64(message.startBlockHeight);
        /* uint64 end_block_height = 3 [jstype = JS_NUMBER]; */
        if (message.endBlockHeight !== 0)
            writer.tag(3, WireType.Varint).uint64(message.endBlockHeight);
        /* bool active = 5; */
        if (message.active !== false)
            writer.tag(5, WireType.Varint).bool(message.active);
        /* bool processed = 6; */
        if (message.processed !== false)
            writer.tag(6, WireType.Varint).bool(message.processed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message tower.v1.Era
 */
export const Era = new Era$Type();
/**
 * @generated ServiceType for protobuf service tower.v1.RewardService
 */
export const RewardService = new ServiceType("tower.v1.RewardService", [
    { name: "Join", options: { "google.api.http": { post: "/v1/join", body: "*" } }, I: JoinRequest, O: JoinResponse },
    { name: "UnJoin", options: { "google.api.http": { post: "/v1/unjoin", body: "*" } }, I: UnJoinRequest, O: UnJoinResponse },
    { name: "UpdatePayoutInfo", options: { "google.api.http": { post: "/v1/payout", body: "*" } }, I: UpdatePayoutInfoRequest, O: UpdatePayoutInfoResponse },
    { name: "InsertTransaction", options: { "google.api.http": { post: "/v1/transaction", body: "*" } }, I: InsertTransactionRequest, O: InsertTransactionResponse },
    { name: "InsertTransactions", options: { "google.api.http": { post: "/v1/transactions", body: "*" } }, I: InsertTransactionsRequest, O: InsertTransactionsResponse },
    { name: "FetchReward", options: { "google.api.http": { get: "/v1/reward" } }, I: FetchRewardRequest, O: FetchRewardResponse },
    { name: "Usage", options: { "google.api.http": { get: "/v1/usage" } }, I: UsageRequest, O: UsageResponse },
    { name: "AverageBalance", options: { "google.api.http": { get: "/v1/average-balance" } }, I: AverageBalanceRequest, O: AverageBalanceResponse },
    { name: "ListRewardCurrencies", options: { "google.api.http": { get: "/v1/reward-currencies" } }, I: ListRewardCurrenciesRequest, O: ListRewardCurrenciesResponse },
    { name: "ListEras", options: { "google.api.http": { get: "/v1/eras" } }, I: ListErasRequest, O: ListErasResponse }
]);

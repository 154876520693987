/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter add_pb_suffix,eslint_disable,ts_nocheck
// @generated from protobuf file "tower/v1/reward.proto" (package "tower.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { RewardService } from "./reward_pb";
import type { ListErasResponse } from "./reward_pb";
import type { ListErasRequest } from "./reward_pb";
import type { ListRewardCurrenciesResponse } from "./reward_pb";
import type { ListRewardCurrenciesRequest } from "./reward_pb";
import type { AverageBalanceResponse } from "./reward_pb";
import type { AverageBalanceRequest } from "./reward_pb";
import type { UsageResponse } from "./reward_pb";
import type { UsageRequest } from "./reward_pb";
import type { FetchRewardResponse } from "./reward_pb";
import type { FetchRewardRequest } from "./reward_pb";
import type { InsertTransactionsResponse } from "./reward_pb";
import type { InsertTransactionsRequest } from "./reward_pb";
import type { InsertTransactionResponse } from "./reward_pb";
import type { InsertTransactionRequest } from "./reward_pb";
import type { UpdatePayoutInfoResponse } from "./reward_pb";
import type { UpdatePayoutInfoRequest } from "./reward_pb";
import type { UnJoinResponse } from "./reward_pb";
import type { UnJoinRequest } from "./reward_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { JoinResponse } from "./reward_pb";
import type { JoinRequest } from "./reward_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * *
 * Reward service specicification
 * NOTICE: Every RPC needs a gRPC metadata with `public_key` and `signature` to authenticate his own identity with the server.
 *
 * @generated from protobuf service tower.v1.RewardService
 */
export interface IRewardServiceClient {
    /**
     * Join the reward program with Payout Information such as address and asset. The time of the request will be used as starting block to counting for your rewards if a reward era is already active.
     *
     * @generated from protobuf rpc: Join(tower.v1.JoinRequest) returns (tower.v1.JoinResponse);
     */
    join(input: JoinRequest, options?: RpcOptions): UnaryCall<JoinRequest, JoinResponse>;
    /**
     * UnJoin the reward program: this will makes you ineligible for the
     * rewards of the current era and all the future one. You can join again, but your reward will start from the new join block height
     *
     * @generated from protobuf rpc: UnJoin(tower.v1.UnJoinRequest) returns (tower.v1.UnJoinResponse);
     */
    unJoin(input: UnJoinRequest, options?: RpcOptions): UnaryCall<UnJoinRequest, UnJoinResponse>;
    /**
     * Update the address and the asset to receive the reward: You should have Join the reward program before calling this.
     *
     * @generated from protobuf rpc: UpdatePayoutInfo(tower.v1.UpdatePayoutInfoRequest) returns (tower.v1.UpdatePayoutInfoResponse);
     */
    updatePayoutInfo(input: UpdatePayoutInfoRequest, options?: RpcOptions): UnaryCall<UpdatePayoutInfoRequest, UpdatePayoutInfoResponse>;
    /**
     * Insert the transaction to be used as activity for calculating rewards. Blinding private keys of relative addresses must be provided
     *
     * @generated from protobuf rpc: InsertTransaction(tower.v1.InsertTransactionRequest) returns (tower.v1.InsertTransactionResponse);
     */
    insertTransaction(input: InsertTransactionRequest, options?: RpcOptions): UnaryCall<InsertTransactionRequest, InsertTransactionResponse>;
    /**
     * Insert batch of transactions to be used as activity for calculating rewards.
     *
     * @generated from protobuf rpc: InsertTransactions(tower.v1.InsertTransactionsRequest) returns (tower.v1.InsertTransactionsResponse);
     */
    insertTransactions(input: InsertTransactionsRequest, options?: RpcOptions): UnaryCall<InsertTransactionsRequest, InsertTransactionsResponse>;
    /**
     * Fetch the calculated payout amount for the given reward era
     *
     * @generated from protobuf rpc: FetchReward(tower.v1.FetchRewardRequest) returns (tower.v1.FetchRewardResponse);
     */
    fetchReward(input: FetchRewardRequest, options?: RpcOptions): UnaryCall<FetchRewardRequest, FetchRewardResponse>;
    /**
     * Retrieve the usage for a given identity in the specified reward era
     *
     * @generated from protobuf rpc: Usage(tower.v1.UsageRequest) returns (tower.v1.UsageResponse);
     */
    usage(input: UsageRequest, options?: RpcOptions): UnaryCall<UsageRequest, UsageResponse>;
    /**
     * Retrieve the average balance for a given identity in the specified reward era
     *
     * @generated from protobuf rpc: AverageBalance(tower.v1.AverageBalanceRequest) returns (tower.v1.AverageBalanceResponse);
     */
    averageBalance(input: AverageBalanceRequest, options?: RpcOptions): UnaryCall<AverageBalanceRequest, AverageBalanceResponse>;
    /**
     * Return list of currencies are eligible for rewards
     *
     * @generated from protobuf rpc: ListRewardCurrencies(tower.v1.ListRewardCurrenciesRequest) returns (tower.v1.ListRewardCurrenciesResponse);
     */
    listRewardCurrencies(input: ListRewardCurrenciesRequest, options?: RpcOptions): UnaryCall<ListRewardCurrenciesRequest, ListRewardCurrenciesResponse>;
    /**
     * Returns list of reward eras
     *
     * @generated from protobuf rpc: ListEras(tower.v1.ListErasRequest) returns (tower.v1.ListErasResponse);
     */
    listEras(input: ListErasRequest, options?: RpcOptions): UnaryCall<ListErasRequest, ListErasResponse>;
}
/**
 * *
 * Reward service specicification
 * NOTICE: Every RPC needs a gRPC metadata with `public_key` and `signature` to authenticate his own identity with the server.
 *
 * @generated from protobuf service tower.v1.RewardService
 */
export class RewardServiceClient implements IRewardServiceClient, ServiceInfo {
    typeName = RewardService.typeName;
    methods = RewardService.methods;
    options = RewardService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Join the reward program with Payout Information such as address and asset. The time of the request will be used as starting block to counting for your rewards if a reward era is already active.
     *
     * @generated from protobuf rpc: Join(tower.v1.JoinRequest) returns (tower.v1.JoinResponse);
     */
    join(input: JoinRequest, options?: RpcOptions): UnaryCall<JoinRequest, JoinResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<JoinRequest, JoinResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * UnJoin the reward program: this will makes you ineligible for the
     * rewards of the current era and all the future one. You can join again, but your reward will start from the new join block height
     *
     * @generated from protobuf rpc: UnJoin(tower.v1.UnJoinRequest) returns (tower.v1.UnJoinResponse);
     */
    unJoin(input: UnJoinRequest, options?: RpcOptions): UnaryCall<UnJoinRequest, UnJoinResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UnJoinRequest, UnJoinResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Update the address and the asset to receive the reward: You should have Join the reward program before calling this.
     *
     * @generated from protobuf rpc: UpdatePayoutInfo(tower.v1.UpdatePayoutInfoRequest) returns (tower.v1.UpdatePayoutInfoResponse);
     */
    updatePayoutInfo(input: UpdatePayoutInfoRequest, options?: RpcOptions): UnaryCall<UpdatePayoutInfoRequest, UpdatePayoutInfoResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdatePayoutInfoRequest, UpdatePayoutInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Insert the transaction to be used as activity for calculating rewards. Blinding private keys of relative addresses must be provided
     *
     * @generated from protobuf rpc: InsertTransaction(tower.v1.InsertTransactionRequest) returns (tower.v1.InsertTransactionResponse);
     */
    insertTransaction(input: InsertTransactionRequest, options?: RpcOptions): UnaryCall<InsertTransactionRequest, InsertTransactionResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<InsertTransactionRequest, InsertTransactionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Insert batch of transactions to be used as activity for calculating rewards.
     *
     * @generated from protobuf rpc: InsertTransactions(tower.v1.InsertTransactionsRequest) returns (tower.v1.InsertTransactionsResponse);
     */
    insertTransactions(input: InsertTransactionsRequest, options?: RpcOptions): UnaryCall<InsertTransactionsRequest, InsertTransactionsResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<InsertTransactionsRequest, InsertTransactionsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Fetch the calculated payout amount for the given reward era
     *
     * @generated from protobuf rpc: FetchReward(tower.v1.FetchRewardRequest) returns (tower.v1.FetchRewardResponse);
     */
    fetchReward(input: FetchRewardRequest, options?: RpcOptions): UnaryCall<FetchRewardRequest, FetchRewardResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<FetchRewardRequest, FetchRewardResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve the usage for a given identity in the specified reward era
     *
     * @generated from protobuf rpc: Usage(tower.v1.UsageRequest) returns (tower.v1.UsageResponse);
     */
    usage(input: UsageRequest, options?: RpcOptions): UnaryCall<UsageRequest, UsageResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<UsageRequest, UsageResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve the average balance for a given identity in the specified reward era
     *
     * @generated from protobuf rpc: AverageBalance(tower.v1.AverageBalanceRequest) returns (tower.v1.AverageBalanceResponse);
     */
    averageBalance(input: AverageBalanceRequest, options?: RpcOptions): UnaryCall<AverageBalanceRequest, AverageBalanceResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<AverageBalanceRequest, AverageBalanceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Return list of currencies are eligible for rewards
     *
     * @generated from protobuf rpc: ListRewardCurrencies(tower.v1.ListRewardCurrenciesRequest) returns (tower.v1.ListRewardCurrenciesResponse);
     */
    listRewardCurrencies(input: ListRewardCurrenciesRequest, options?: RpcOptions): UnaryCall<ListRewardCurrenciesRequest, ListRewardCurrenciesResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListRewardCurrenciesRequest, ListRewardCurrenciesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Returns list of reward eras
     *
     * @generated from protobuf rpc: ListEras(tower.v1.ListErasRequest) returns (tower.v1.ListErasResponse);
     */
    listEras(input: ListErasRequest, options?: RpcOptions): UnaryCall<ListErasRequest, ListErasResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListErasRequest, ListErasResponse>("unary", this._transport, method, opt, input);
    }
}

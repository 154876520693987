import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { AdminServiceClient } from '../../api-spec/protobuf/gen/js/tower/v1/admin_pb.client';
import { RewardServiceClient } from '../../api-spec/protobuf/gen/js/tower/v1/reward_pb.client';
import { config } from '../../app/config';
import type { RootState } from '../../app/store';

export type NetworkString = 'regtest' | 'testnet' | 'liquid';

export interface SettingsState {
  network: NetworkString;
  authenticationToken?: string;
  macaroon?: string;
  towerDaemonEndpoint: string;
  explorersLiquidAPI: string[];
  explorersLiquidUI: string[];
  explorerLiquidAPI: string;
  explorerLiquidUI: string;
  lastBlockHeight: number;
  isAdvancedMode: boolean;
}

export const initialState: SettingsState = {
  network: config.chain,
  macaroon: '',
  towerDaemonEndpoint: config.towerDaemonEndpoint,
  explorersLiquidAPI: [
    'http://localhost:3001',
    'https://blockstream.info/liquidtestnet/api',
    'https://blockstream.info/liquid/api',
  ],
  explorersLiquidUI: [
    'http://localhost:5001',
    'https://blockstream.info/liquidtestnet',
    'https://blockstream.info/liquid',
  ],
  explorerLiquidAPI: config.explorerLiquidAPI,
  explorerLiquidUI: config.explorerLiquidUI,
  lastBlockHeight: 0,
  isAdvancedMode: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setMacaroon: (state, action: PayloadAction<string>) => {
      state.macaroon = action.payload;
    },
    setTowerDaemonEndpoint: (state, action: PayloadAction<string>) => {
      state.towerDaemonEndpoint = action.payload;
    },
    logout: (state) => {
      state.authenticationToken = initialState.authenticationToken;
      state.macaroon = initialState.macaroon;
      state.towerDaemonEndpoint = initialState.towerDaemonEndpoint;
    },
    addExplorerLiquidAPI: (state, action: PayloadAction<string>) => {
      if (!state.explorersLiquidAPI.includes(action.payload)) {
        state.explorersLiquidAPI = [...state.explorersLiquidAPI, action.payload];
      }
    },
    addExplorerLiquidUI: (state, action: PayloadAction<string>) => {
      if (!state.explorersLiquidUI.includes(action.payload)) {
        state.explorersLiquidUI = [...state.explorersLiquidUI, action.payload];
      }
    },
    setNetwork: (state, action: PayloadAction<NetworkString>) => {
      state.network = action.payload;
    },
    setExplorerLiquidAPI: (state, action: PayloadAction<string>) => {
      state.explorerLiquidAPI = action.payload;
    },
    setExplorerLiquidUI: (state, action: PayloadAction<string>) => {
      state.explorerLiquidUI = action.payload;
    },
    setLastBlockHeight: (state, action: PayloadAction<number>) => {
      state.lastBlockHeight = action.payload;
    },
    resetSettings: () => initialState,
    setIsAdvancedMode: (state, action: PayloadAction<boolean>) => {
      state.isAdvancedMode = action.payload;
    },
  },
});

export function selectMacaroon(state: RootState): string {
  return state.settings.macaroon || '';
}

export function selectBaseUrl(state: RootState): string {
  return state.settings.towerDaemonEndpoint;
}

export function selectRewardClient(state: RootState): RewardServiceClient {
  return new RewardServiceClient(
    new GrpcWebFetchTransport({
      baseUrl: selectBaseUrl(state),
    })
  );
}

export function selectAdminClient(state: RootState): AdminServiceClient {
  return new AdminServiceClient(
    new GrpcWebFetchTransport({
      baseUrl: selectBaseUrl(state),
    })
  );
}

export const {
  setTowerDaemonEndpoint,
  setMacaroon,
  resetSettings,
  logout,
  addExplorerLiquidAPI,
  addExplorerLiquidUI,
  setExplorerLiquidAPI,
  setExplorerLiquidUI,
  setLastBlockHeight,
  setIsAdvancedMode,
  setNetwork,
} = settingsSlice.actions;

export default settingsSlice.reducer;

import { Typography } from 'antd';

import { useGetInfoQuery } from '../../apis/admin.api';

const { Title } = Typography;

export function BuildInfo(): JSX.Element {
  const { data: info } = useGetInfoQuery();

  return (
    <>
      <Title level={3}>Daemon Version</Title>
      <p>{info?.buildInfo?.version ?? 'N/A'}</p>
    </>
  );
}

/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter add_pb_suffix,eslint_disable,ts_nocheck
// @generated from protobuf file "tower/v1/admin.proto" (package "tower.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { AdminService } from "./admin_pb";
import type { RevokeTokenResponse } from "./admin_pb";
import type { RevokeTokenRequest } from "./admin_pb";
import type { ListRewardErasResponse } from "./admin_pb";
import type { ListRewardErasRequest } from "./admin_pb";
import type { ListCurrenciesResponse } from "./admin_pb";
import type { ListCurrenciesRequest } from "./admin_pb";
import type { CalculateRewardDistributionResponse } from "./admin_pb";
import type { CalculateRewardDistributionRequest } from "./admin_pb";
import type { RewardDistributionResponse } from "./admin_pb";
import type { RewardDistributionRequest } from "./admin_pb";
import type { AdminAverageBalanceResponse } from "./admin_pb";
import type { AdminAverageBalanceRequest } from "./admin_pb";
import type { AdminUsageResponse } from "./admin_pb";
import type { AdminUsageRequest } from "./admin_pb";
import type { ListPaymentsResponse } from "./admin_pb";
import type { ListPaymentsRequest } from "./admin_pb";
import type { ListIdentitiesResponse } from "./admin_pb";
import type { ListIdentitiesRequest } from "./admin_pb";
import type { RemoveCurrencyResponse } from "./admin_pb";
import type { RemoveCurrencyRequest } from "./admin_pb";
import type { InsertCurrencyResponse } from "./admin_pb";
import type { InsertCurrencyRequest } from "./admin_pb";
import type { RemoveRewardEraResponse } from "./admin_pb";
import type { RemoveRewardEraRequest } from "./admin_pb";
import type { InsertRewardEraResponse } from "./admin_pb";
import type { InsertRewardEraRequest } from "./admin_pb";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetInfoResponse } from "./admin_pb";
import type { GetInfoRequest } from "./admin_pb";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * *
 * Admin service spec
 *
 * @generated from protobuf service tower.v1.AdminService
 */
export interface IAdminServiceClient {
    /**
     * GetInfo returns information about currencies being inserted and active reward era
     *
     * @generated from protobuf rpc: GetInfo(tower.v1.GetInfoRequest) returns (tower.v1.GetInfoResponse);
     */
    getInfo(input: GetInfoRequest, options?: RpcOptions): UnaryCall<GetInfoRequest, GetInfoResponse>;
    /**
     * InsertRewardEra inserts a reward era and reward fund, it can be used to update existing era also
     *
     * @generated from protobuf rpc: InsertRewardEra(tower.v1.InsertRewardEraRequest) returns (tower.v1.InsertRewardEraResponse);
     */
    insertRewardEra(input: InsertRewardEraRequest, options?: RpcOptions): UnaryCall<InsertRewardEraRequest, InsertRewardEraResponse>;
    /**
     * Removes specific reward era
     *
     * @generated from protobuf rpc: RemoveRewardEra(tower.v1.RemoveRewardEraRequest) returns (tower.v1.RemoveRewardEraResponse);
     */
    removeRewardEra(input: RemoveRewardEraRequest, options?: RpcOptions): UnaryCall<RemoveRewardEraRequest, RemoveRewardEraResponse>;
    /**
     * Insert the supported currencies from the curren reward era and onward
     *
     * @generated from protobuf rpc: InsertCurrency(tower.v1.InsertCurrencyRequest) returns (tower.v1.InsertCurrencyResponse);
     */
    insertCurrency(input: InsertCurrencyRequest, options?: RpcOptions): UnaryCall<InsertCurrencyRequest, InsertCurrencyResponse>;
    /**
     * Removes specific currency
     *
     * @generated from protobuf rpc: RemoveCurrency(tower.v1.RemoveCurrencyRequest) returns (tower.v1.RemoveCurrencyResponse);
     */
    removeCurrency(input: RemoveCurrencyRequest, options?: RpcOptions): UnaryCall<RemoveCurrencyRequest, RemoveCurrencyResponse>;
    /**
     * List add identities pubkey registered
     *
     * @generated from protobuf rpc: ListIdentities(tower.v1.ListIdentitiesRequest) returns (tower.v1.ListIdentitiesResponse);
     */
    listIdentities(input: ListIdentitiesRequest, options?: RpcOptions): UnaryCall<ListIdentitiesRequest, ListIdentitiesResponse>;
    /**
     * List the blockchain transactions normalized per type INBOUND or OUTBOUND.
     *
     * @generated from protobuf rpc: ListPayments(tower.v1.ListPaymentsRequest) returns (tower.v1.ListPaymentsResponse);
     */
    listPayments(input: ListPaymentsRequest, options?: RpcOptions): UnaryCall<ListPaymentsRequest, ListPaymentsResponse>;
    /**
     * Retrieve the usage for a given identity in the specified reward era
     *
     * @generated from protobuf rpc: AdminUsage(tower.v1.AdminUsageRequest) returns (tower.v1.AdminUsageResponse);
     */
    adminUsage(input: AdminUsageRequest, options?: RpcOptions): UnaryCall<AdminUsageRequest, AdminUsageResponse>;
    /**
     * Retrieve the average balance for a given identity in the specified reward era
     *
     * @generated from protobuf rpc: AdminAverageBalance(tower.v1.AdminAverageBalanceRequest) returns (tower.v1.AdminAverageBalanceResponse);
     */
    adminAverageBalance(input: AdminAverageBalanceRequest, options?: RpcOptions): UnaryCall<AdminAverageBalanceRequest, AdminAverageBalanceResponse>;
    /**
     * If a specific identity is given will return only the RewardInfo for that specific identity.
     *
     * @generated from protobuf rpc: RewardDistribution(tower.v1.RewardDistributionRequest) returns (tower.v1.RewardDistributionResponse);
     */
    rewardDistribution(input: RewardDistributionRequest, options?: RpcOptions): UnaryCall<RewardDistributionRequest, RewardDistributionResponse>;
    /**
     * Starts reward distribution calculation for reward era
     *
     * @generated from protobuf rpc: CalculateRewardDistribution(tower.v1.CalculateRewardDistributionRequest) returns (tower.v1.CalculateRewardDistributionResponse);
     */
    calculateRewardDistribution(input: CalculateRewardDistributionRequest, options?: RpcOptions): UnaryCall<CalculateRewardDistributionRequest, CalculateRewardDistributionResponse>;
    /**
     * Retrieve all existing currencies
     *
     * @generated from protobuf rpc: ListCurrencies(tower.v1.ListCurrenciesRequest) returns (tower.v1.ListCurrenciesResponse);
     */
    listCurrencies(input: ListCurrenciesRequest, options?: RpcOptions): UnaryCall<ListCurrenciesRequest, ListCurrenciesResponse>;
    /**
     * Retrieve all existing reward eras
     *
     * @generated from protobuf rpc: ListRewardEras(tower.v1.ListRewardErasRequest) returns (tower.v1.ListRewardErasResponse);
     */
    listRewardEras(input: ListRewardErasRequest, options?: RpcOptions): UnaryCall<ListRewardErasRequest, ListRewardErasResponse>;
    /**
     * RevokeToken revokes identity token
     *
     * @generated from protobuf rpc: RevokeToken(tower.v1.RevokeTokenRequest) returns (tower.v1.RevokeTokenResponse);
     */
    revokeToken(input: RevokeTokenRequest, options?: RpcOptions): UnaryCall<RevokeTokenRequest, RevokeTokenResponse>;
}
/**
 * *
 * Admin service spec
 *
 * @generated from protobuf service tower.v1.AdminService
 */
export class AdminServiceClient implements IAdminServiceClient, ServiceInfo {
    typeName = AdminService.typeName;
    methods = AdminService.methods;
    options = AdminService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * GetInfo returns information about currencies being inserted and active reward era
     *
     * @generated from protobuf rpc: GetInfo(tower.v1.GetInfoRequest) returns (tower.v1.GetInfoResponse);
     */
    getInfo(input: GetInfoRequest, options?: RpcOptions): UnaryCall<GetInfoRequest, GetInfoResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetInfoRequest, GetInfoResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * InsertRewardEra inserts a reward era and reward fund, it can be used to update existing era also
     *
     * @generated from protobuf rpc: InsertRewardEra(tower.v1.InsertRewardEraRequest) returns (tower.v1.InsertRewardEraResponse);
     */
    insertRewardEra(input: InsertRewardEraRequest, options?: RpcOptions): UnaryCall<InsertRewardEraRequest, InsertRewardEraResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<InsertRewardEraRequest, InsertRewardEraResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Removes specific reward era
     *
     * @generated from protobuf rpc: RemoveRewardEra(tower.v1.RemoveRewardEraRequest) returns (tower.v1.RemoveRewardEraResponse);
     */
    removeRewardEra(input: RemoveRewardEraRequest, options?: RpcOptions): UnaryCall<RemoveRewardEraRequest, RemoveRewardEraResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveRewardEraRequest, RemoveRewardEraResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Insert the supported currencies from the curren reward era and onward
     *
     * @generated from protobuf rpc: InsertCurrency(tower.v1.InsertCurrencyRequest) returns (tower.v1.InsertCurrencyResponse);
     */
    insertCurrency(input: InsertCurrencyRequest, options?: RpcOptions): UnaryCall<InsertCurrencyRequest, InsertCurrencyResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<InsertCurrencyRequest, InsertCurrencyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Removes specific currency
     *
     * @generated from protobuf rpc: RemoveCurrency(tower.v1.RemoveCurrencyRequest) returns (tower.v1.RemoveCurrencyResponse);
     */
    removeCurrency(input: RemoveCurrencyRequest, options?: RpcOptions): UnaryCall<RemoveCurrencyRequest, RemoveCurrencyResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveCurrencyRequest, RemoveCurrencyResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List add identities pubkey registered
     *
     * @generated from protobuf rpc: ListIdentities(tower.v1.ListIdentitiesRequest) returns (tower.v1.ListIdentitiesResponse);
     */
    listIdentities(input: ListIdentitiesRequest, options?: RpcOptions): UnaryCall<ListIdentitiesRequest, ListIdentitiesResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListIdentitiesRequest, ListIdentitiesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * List the blockchain transactions normalized per type INBOUND or OUTBOUND.
     *
     * @generated from protobuf rpc: ListPayments(tower.v1.ListPaymentsRequest) returns (tower.v1.ListPaymentsResponse);
     */
    listPayments(input: ListPaymentsRequest, options?: RpcOptions): UnaryCall<ListPaymentsRequest, ListPaymentsResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListPaymentsRequest, ListPaymentsResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve the usage for a given identity in the specified reward era
     *
     * @generated from protobuf rpc: AdminUsage(tower.v1.AdminUsageRequest) returns (tower.v1.AdminUsageResponse);
     */
    adminUsage(input: AdminUsageRequest, options?: RpcOptions): UnaryCall<AdminUsageRequest, AdminUsageResponse> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<AdminUsageRequest, AdminUsageResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve the average balance for a given identity in the specified reward era
     *
     * @generated from protobuf rpc: AdminAverageBalance(tower.v1.AdminAverageBalanceRequest) returns (tower.v1.AdminAverageBalanceResponse);
     */
    adminAverageBalance(input: AdminAverageBalanceRequest, options?: RpcOptions): UnaryCall<AdminAverageBalanceRequest, AdminAverageBalanceResponse> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<AdminAverageBalanceRequest, AdminAverageBalanceResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * If a specific identity is given will return only the RewardInfo for that specific identity.
     *
     * @generated from protobuf rpc: RewardDistribution(tower.v1.RewardDistributionRequest) returns (tower.v1.RewardDistributionResponse);
     */
    rewardDistribution(input: RewardDistributionRequest, options?: RpcOptions): UnaryCall<RewardDistributionRequest, RewardDistributionResponse> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<RewardDistributionRequest, RewardDistributionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Starts reward distribution calculation for reward era
     *
     * @generated from protobuf rpc: CalculateRewardDistribution(tower.v1.CalculateRewardDistributionRequest) returns (tower.v1.CalculateRewardDistributionResponse);
     */
    calculateRewardDistribution(input: CalculateRewardDistributionRequest, options?: RpcOptions): UnaryCall<CalculateRewardDistributionRequest, CalculateRewardDistributionResponse> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<CalculateRewardDistributionRequest, CalculateRewardDistributionResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve all existing currencies
     *
     * @generated from protobuf rpc: ListCurrencies(tower.v1.ListCurrenciesRequest) returns (tower.v1.ListCurrenciesResponse);
     */
    listCurrencies(input: ListCurrenciesRequest, options?: RpcOptions): UnaryCall<ListCurrenciesRequest, ListCurrenciesResponse> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListCurrenciesRequest, ListCurrenciesResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * Retrieve all existing reward eras
     *
     * @generated from protobuf rpc: ListRewardEras(tower.v1.ListRewardErasRequest) returns (tower.v1.ListRewardErasResponse);
     */
    listRewardEras(input: ListRewardErasRequest, options?: RpcOptions): UnaryCall<ListRewardErasRequest, ListRewardErasResponse> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListRewardErasRequest, ListRewardErasResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * RevokeToken revokes identity token
     *
     * @generated from protobuf rpc: RevokeToken(tower.v1.RevokeTokenRequest) returns (tower.v1.RevokeTokenResponse);
     */
    revokeToken(input: RevokeTokenRequest, options?: RpcOptions): UnaryCall<RevokeTokenRequest, RevokeTokenResponse> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<RevokeTokenRequest, RevokeTokenResponse>("unary", this._transport, method, opt, input);
    }
}

import { Table } from 'antd';

import { formatFiat } from '../../utils';

interface CurrencyAmountTableProps {
  data?: { [key: string]: number };
}

export function CurrencyAmountTable({ data }: CurrencyAmountTableProps): JSX.Element {
  const CURRENCY_AMOUNT_PAGE_SIZE = 5;

  return (
    <Table
      columns={[
        {
          title: 'Currency',
          dataIndex: 'currency',
          key: 'currency',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          render: (amount) => formatFiat(amount),
        },
      ]}
      rowKey="currency"
      dataSource={Object.entries(data || {}).map(([currency, amount]) => ({ currency, amount }))}
      pagination={
        Object.keys(data || {}).length > CURRENCY_AMOUNT_PAGE_SIZE && {
          total: Object.keys(data || {}).length,
          pageSize: CURRENCY_AMOUNT_PAGE_SIZE,
        }
      }
    />
  );
}

import { Button, Modal, notification } from 'antd';
import { address } from 'ldk';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';

import { esploraApi } from '../../apis/esplora.api';
import { rewardApi } from '../../apis/reward.api';
import { useTypedDispatch, useTypedSelector } from '../../app/store';
import { KeyQRCode } from '../../common/KeyQRCode';

import type { TestingTowerIdName } from './testingSlice';

interface DepositAddressModalProps {
  depositAddressModalVisible: boolean;
  setDepositAddressModalVisible: Dispatch<SetStateAction<boolean>>;
  name: TestingTowerIdName;
}

export function DepositAddressModal({
  depositAddressModalVisible,
  setDepositAddressModalVisible,
  name,
}: DepositAddressModalProps): JSX.Element {
  const dispatch = useTypedDispatch();
  const confidentialAddress = useTypedSelector(({ testing }) => testing.wallets[name].confidentialAddress);
  const testingWallets = useTypedSelector(({ testing }) => testing.wallets);

  return (
    <Modal
      visible={depositAddressModalVisible}
      title="Deposit Address"
      onCancel={() => {
        setDepositAddressModalVisible(false);
      }}
      footer={[
        <Button
          type="primary"
          key="ok"
          onClick={async () => {
            try {
              setDepositAddressModalVisible(false);
              if (confidentialAddress) {
                const res = await dispatch(
                  esploraApi.endpoints.getTransactions.initiate(confidentialAddress, { forceRefetch: true })
                ).unwrap();
                if (res.length === 0) throw new Error('No transaction found');
                const blinding = {
                  [address.toOutputScript(confidentialAddress).toString('hex')]:
                    testingWallets[name].blindingPrivateKey ?? '',
                };
                await dispatch(
                  rewardApi.endpoints.insertTransaction.initiate({
                    txInfo: { txid: res[0].txid, blinding },
                    walletName: name,
                  })
                ).unwrap();
              }
            } catch (err) {
              console.debug(err);
              if (err instanceof Error) {
                notification.error({ message: (err as any).message, key: (err as any).message });
              } else {
                notification.error({ message: err as string, key: err as string });
              }
            }
          }}
        >
          I made the deposit
        </Button>,
      ]}
    >
      <KeyQRCode addr={confidentialAddress ?? ''} justify="center" />
    </Modal>
  );
}

import { RpcError } from '@protobuf-ts/runtime-rpc';
import { notification } from 'antd';

import { sleep } from './sleep';

// Retry once after 500ms in case daemon fails to reply
export const retryRtkRequest = async <T>(
  requestCb: () => Promise<{ data: T }>
): Promise<{ data: T } | { error: string }> => {
  try {
    return await requestCb();
  } catch (error) {
    // Check if error is NOT_FOUND and return empty data instead
    if (error instanceof RpcError && error.code === 'NOT_FOUND') {
      console.debug(`${error.methodName}: ${error.message}`);
      // Special case for CalculateRewardDistribution
      // error.code 'NOT_FOUND' should be treated as error
      // Can be several errors (usage not found, reward era not found)
      if (error.methodName === 'CalculateRewardDistribution') {
        notification.error({ message: error.message, duration: 7, key: error.message });
      }
      /// Special case for InsertTransaction
      if (error.methodName === 'InsertTransaction') {
        notification.info({ message: 'Transaction will be inserted after block confirmation' });
        // Retry once after block confirmation
        setTimeout(async () => {
          try {
            return await requestCb();
          } catch (insertTransactionError) {
            notification.error({
              message: (insertTransactionError as any).message,
              duration: 7,
              key: (insertTransactionError as any).message,
            });
            return false;
          }
        }, 60_000);
      }
      ///
      if (error.methodName === 'AdminAverageBalance') {
        notification.error({ message: error.message, duration: 7, key: error.message });
      }
      ///
      if (error.methodName === 'AverageBalance') {
        notification.error({ message: `AverageBalance: ${error.message}`, duration: 7, key: error.message });
      }
      return { data: [] as unknown as T };
    }
    // Otherwise retry
    try {
      console.debug('retry RTK request');
      await sleep(500);
      return await requestCb();
    } catch (err) {
      if (err instanceof RpcError) {
        console.error(`${err.methodName} failure -`, err);
        notification.error({ message: err.message, duration: 7, key: err.message });
      }
      if (err instanceof Error || err instanceof SyntaxError) {
        notification.error({ message: err.message, duration: 7, key: err.message });
      }
      return { error: (err as any).message };
    }
  }
};

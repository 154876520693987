const ONBOARDING_ROUTE = '/onboarding';
const CURRENCY_ROUTE = '/currency';
const IDENTITY_PUBLIC_KEYS_ROUTE = '/identity/public-keys';
const IDENTITY_REWARD_ERAS_ROUTE = '/identity/reward-eras';
const IDENTITY_INFO_ROUTE = '/identity/info';
const REWARD_ERA_ROUTE = '/reward/era';
const SETTINGS_ROUTE = '/settings';
const TESTING_ROUTE = '/testing';
const TESTING_REWARD_ERAS_ROUTE = '/testing/reward-eras';
const TESTING_IDENTITY_INFO_ROUTE = '/testing/identity-info';

export {
  ONBOARDING_ROUTE,
  CURRENCY_ROUTE,
  IDENTITY_PUBLIC_KEYS_ROUTE,
  IDENTITY_REWARD_ERAS_ROUTE,
  IDENTITY_INFO_ROUTE,
  REWARD_ERA_ROUTE,
  TESTING_ROUTE,
  TESTING_REWARD_ERAS_ROUTE,
  TESTING_IDENTITY_INFO_ROUTE,
  SETTINGS_ROUTE,
};

import type { FormInstance } from 'antd';
import { Button, Col, Form, Input, Row } from 'antd';
import type { Dispatch, SetStateAction } from 'react';

import type { Timeframe } from '../../../api-spec/protobuf/gen/js/tower/v1/admin_pb';
import { useInsertRewardEraMutation } from '../../../apis/admin.api';
import { BlockPicker } from '../../../common/BlockPicker';
import { toSatoshi } from '../../../utils';

export interface InsertRewardEraFormValues {
  rewardFundCurrencySymbol: string;
  rewardFundAmount: number;
  startBlockHeight: Timeframe['startBlockHeight'];
  endBlockHeight: Timeframe['endBlockHeight'];
}

interface InsertRewardEraFormProps {
  form: FormInstance<InsertRewardEraFormValues>;
  setShowInsertRewardEraFormModal: Dispatch<SetStateAction<boolean>>;
}

export function InsertRewardEraForm({
  form,
  setShowInsertRewardEraFormModal,
}: InsertRewardEraFormProps): JSX.Element {
  const [insertRewardEra, { isLoading: insertRewardEraIsLoading }] = useInsertRewardEraMutation();

  const onSubmit = async () => {
    const { rewardFundCurrencySymbol, rewardFundAmount, startBlockHeight, endBlockHeight } =
      await form.validateFields();
    const res = await insertRewardEra({
      rewardEra: 0,
      rewardFund: { [rewardFundCurrencySymbol]: toSatoshi(rewardFundAmount) },
      timeframe: { startBlockHeight, endBlockHeight },
    });
    if ('error' in res) return;
    form.resetFields();
    setShowInsertRewardEraFormModal(false);
  };

  return (
    <Form
      className="insertRewardEra-container text-start"
      form={form}
      layout="vertical"
      name="insertRewardEra_form"
      onFinish={onSubmit}
      validateTrigger="onBlur"
    >
      <div className="insertRewardEra-content">
        <Form.Item
          label="Reward Fund Currency Symbol"
          name="rewardFundCurrencySymbol"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Reward Fund Amount" name="rewardFundAmount" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Start Block Height" name="startBlockHeight" rules={[{ required: true }]}>
          <BlockPicker
            setBlockHeight={(blockHeight?: number) => form.setFieldsValue({ startBlockHeight: blockHeight })}
          />
        </Form.Item>
        <Form.Item label="End Block Height" name="endBlockHeight" rules={[{ required: true }]}>
          <BlockPicker
            setBlockHeight={(blockHeight?: number) => form.setFieldsValue({ endBlockHeight: blockHeight })}
          />
        </Form.Item>
      </div>
      <Row justify="end">
        <Col>
          <Button type="primary" loading={insertRewardEraIsLoading} htmlType="submit">
            Insert Reward Era
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

import { combineReducers } from '@reduxjs/toolkit';

import { esploraApi } from '../apis/esplora.api';
import { towerApi } from '../apis/tower.api';
import { testingSlice } from '../features/TxsTesting/testingSlice';
import { settingsSlice } from '../features/settings/settingsSlice';

export const rootReducer = combineReducers({
  [settingsSlice.name]: settingsSlice.reducer,
  [testingSlice.name]: testingSlice.reducer,
  [towerApi.reducerPath]: towerApi.reducer,
  [esploraApi.reducerPath]: esploraApi.reducer,
});

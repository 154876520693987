import { Switch, Typography } from 'antd';

import { useTypedDispatch, useTypedSelector } from '../../app/store';

import { setIsAdvancedMode } from './settingsSlice';

const { Title } = Typography;

export function AdvancedModeSwitch(): JSX.Element {
  const dispatch = useTypedDispatch();
  const isAdvancedMode = useTypedSelector(({ settings }) => settings.isAdvancedMode);

  const onAdvancedModeChange = (checked: boolean) => {
    dispatch(setIsAdvancedMode(checked));
  };

  return (
    <>
      <Title level={3}>Advanced mode</Title>
      <Switch onChange={onAdvancedModeChange} checked={isAdvancedMode} />
    </>
  );
}

import './rewardErasTable.scss';
import { Form, Popconfirm, Popover, Table, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { FundInfo } from '../api-spec/protobuf/gen/js/tower/v1/admin_pb';
import {
  useCalculateRewardDistributionMutation,
  useInsertRewardEraMutation,
  useListRewardErasQuery,
  useRemoveRewardEraMutation,
} from '../apis/admin.api';
import { useTypedDispatch, useTypedSelector } from '../app/store';
import { IDENTITY_INFO_ROUTE, TESTING_IDENTITY_INFO_ROUTE } from '../routes/constants';
import { formatFiat, sleep, toSatoshi } from '../utils';
import { convertBlockHeightToDate } from '../utils/esplora';

import { EditableCell } from './EditableCell';

interface RewardErasTableProps {
  hideEditCol?: boolean;
  testing?: boolean;
  pubkey?: string;
}

interface RewardErasFormatted {
  startDate: string;
  endDate: string;
  active: string;
  processed: string;
  currency: string;
  amount: string;
  rewardEraId: number;
  startBlockHeight: number;
  endBlockHeight: number;
  turnoverThreshold: number;
  fund?: FundInfo;
}

export function RewardErasTable({
  hideEditCol = false,
  testing = false,
  pubkey,
}: RewardErasTableProps): JSX.Element {
  const REWARD_ERA_PAGE_SIZE = 10;
  const [form] = Form.useForm();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { data: rewardEras, refetch: refetchRewardEras } = useListRewardErasQuery();
  const [insertRewardEra] = useInsertRewardEraMutation();
  const [removeRewardEra] = useRemoveRewardEraMutation();
  const [rewardErasFormatted, setRewardErasFormatted] = useState<RewardErasFormatted[]>();
  const lastBlockHeight = useTypedSelector(({ settings }) => settings.lastBlockHeight);
  const [calculateRewardDistribution] = useCalculateRewardDistributionMutation();

  useEffect(() => {
    if (rewardEras && rewardEras.length > 0) {
      const newRewardEras = rewardEras.map((era) => ({
        ...era,
        startDate: convertBlockHeightToDate(lastBlockHeight, era.startBlockHeight).format(
          'YYYY-MM-DD HH:mma'
        ),
        endDate: convertBlockHeightToDate(lastBlockHeight, era.endBlockHeight).format('YYYY-MM-DD HH:mma'),
        active: era.active ? '✅' : '❌',
        processed: era.processed ? '✅' : '❌',
        currency: Object.keys(era.fund?.currencyAmountPair || {})[0],
        amount: formatFiat(Object.values(era.fund?.currencyAmountPair || {})[0]),
      }));
      setRewardErasFormatted(newRewardEras);
    }
  }, [dispatch, lastBlockHeight, rewardEras]);

  const [editingRewardEraId, setEditingRewardEraId] = useState<number>();

  const isEditing = (record: any) => record.rewardEraId === editingRewardEraId;

  const edit = (record: RewardErasFormatted) => {
    form.setFieldsValue({ ...record });
    setEditingRewardEraId(record.rewardEraId);
  };

  const cancel = () => {
    setEditingRewardEraId(undefined);
  };

  const save = async (rewardEraId: number) => {
    try {
      const row = await form.validateFields();
      const res = await insertRewardEra({
        rewardEra: rewardEraId,
        rewardFund: { [row.currency]: toSatoshi(row.amount) },
        timeframe: { startBlockHeight: row.startBlockHeight, endBlockHeight: row.endBlockHeight },
      });
      if ('error' in res) {
        console.error('Reward Era upsert failed:', res.error);
      }
      setEditingRewardEraId(undefined);
    } catch (errInfo) {
      console.error('Reward Era upsert failed:', errInfo);
    }
  };

  const handleDeleteRewardEra = async (rewardEra: number) => {
    try {
      const res = await removeRewardEra({ rewardEra });
      if ('error' in res) {
        console.error('Reward Era upsert delete:', res.error);
      }
    } catch (err) {
      console.error('Reward Era delete failed:', err);
    }
  };

  const columns = rewardErasFormatted?.length
    ? [
        {
          title: 'Reward Era Id',
          dataIndex: 'rewardEraId',
          key: 'rewardEraId',
          editable: false,
        },
        {
          title: 'Start Block Height',
          dataIndex: 'startBlockHeight',
          key: 'startBlockHeight',
          editable: true,
          render: (startBlockHeight: string, record: any) => (
            <Popover trigger="hover" content={<div>{record.startDate}</div>}>
              {startBlockHeight}
            </Popover>
          ),
        },
        {
          title: 'End Block Height',
          dataIndex: 'endBlockHeight',
          key: 'endBlockHeight',
          editable: true,
          render: (endBlockHeight: string, record: any) => (
            <Popover trigger="hover" content={<div>{record.endDate}</div>}>
              {endBlockHeight}
            </Popover>
          ),
        },
        {
          title: 'Active',
          dataIndex: 'active',
          key: 'active',
          editable: false,
        },
        {
          title: 'Processed',
          dataIndex: 'processed',
          key: 'processed',
          editable: false,
        },
        {
          title: 'Turnover Threshold',
          dataIndex: 'turnoverThreshold',
          key: 'turnoverThreshold',
          editable: false,
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          key: 'currency',
          editable: true,
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          editable: true,
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          hidden: hideEditCol,
          editable: false,
          render: (_: any, record: RewardErasFormatted) => {
            const editable = isEditing(record);
            return editable ? (
              <span>
                <Typography.Link onClick={() => save(record.rewardEraId)} className="mr-2">
                  Save
                </Typography.Link>
                <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>Cancel</a>
                </Popconfirm>
              </span>
            ) : (
              <>
                <Typography.Link
                  disabled={editingRewardEraId !== undefined || record.processed === '✅'}
                  onClick={() => edit(record)}
                  className="mr-2"
                >
                  Edit
                </Typography.Link>
                <Typography.Link
                  disabled={editingRewardEraId !== undefined || record.processed === '✅'}
                  onClick={async () => {
                    await calculateRewardDistribution({ rewardEra: record.rewardEraId });
                    await sleep(1000);
                    await refetchRewardEras();
                  }}
                  className="mr-2"
                >
                  Process
                </Typography.Link>
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => handleDeleteRewardEra(record.rewardEraId)}
                >
                  <Typography.Link disabled={editingRewardEraId !== undefined || record.processed === '✅'}>
                    Delete
                  </Typography.Link>
                </Popconfirm>
              </>
            );
          },
        },
      ].filter((item) => !item.hidden)
    : [];

  const mergedColumns = columns.map((col) => {
    if (hideEditCol || !col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: RewardErasFormatted) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        rowKey="rewardEraId"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        onRow={(record) => ({
          onClick: () => {
            if (pubkey) {
              if (testing) {
                navigate(TESTING_IDENTITY_INFO_ROUTE, {
                  state: { pubkey, rewardEraId: record.rewardEraId },
                });
              } else {
                navigate(IDENTITY_INFO_ROUTE, {
                  state: { pubkey, rewardEraId: record.rewardEraId },
                });
              }
            }
          },
        })}
        columns={mergedColumns}
        dataSource={rewardErasFormatted}
        pagination={
          rewardErasFormatted &&
          rewardErasFormatted.length > REWARD_ERA_PAGE_SIZE && {
            total: rewardErasFormatted.length,
            pageSize: REWARD_ERA_PAGE_SIZE,
          }
        }
      />
    </Form>
  );
}

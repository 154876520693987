import Icon from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { useAdminAverageBalanceQuery, useAdminUsageQuery } from '../../../apis/admin.api';
import { ReactComponent as chevronRight } from '../../../assets/images/chevron-right.svg';
import { IDENTITY_PUBLIC_KEYS_ROUTE, IDENTITY_REWARD_ERAS_ROUTE } from '../../../routes/constants';
import { CurrencyAmountTable } from '../CurrencyAmountTable';
import { PaymentTable } from '../PaymentTable';
import { RewardDistributionTable } from '../RewardDistributionTable';

const { Title } = Typography;

export function IdentityInfo(): JSX.Element {
  const { state } = useLocation() as { state: { pubkey: string; rewardEraId: number } };

  const { data: usage } = useAdminUsageQuery({
    identity: {
      rewardEra: state.rewardEraId,
      publicKey: state.pubkey,
    },
  });
  const { data: averageBalance } = useAdminAverageBalanceQuery({
    identity: {
      rewardEra: state.rewardEraId,
      publicKey: state.pubkey,
    },
  });

  return (
    <div className="identity-infos-page">
      <Breadcrumb separator={<Icon component={chevronRight} />}>
        <Breadcrumb.Item>
          <Link to={IDENTITY_PUBLIC_KEYS_ROUTE}>Public Keys</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={IDENTITY_REWARD_ERAS_ROUTE} state={{ pubkey: state.pubkey }}>
            Reward Eras
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Identity Info</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={2}>{`Reward ID #${state.rewardEraId}`}</Title>

      <Title level={2}>Usage</Title>
      <CurrencyAmountTable data={usage} />

      <Title level={2} className="mt-8">
        Average Balance
      </Title>
      <CurrencyAmountTable data={averageBalance} />

      <Title level={2} className="mt-8">
        Payments
      </Title>
      <PaymentTable state={state} />

      <Title level={2} className="mt-12">
        Reward Distribution
      </Title>
      <RewardDistributionTable state={state} />
    </div>
  );
}

import { Col, Row, Select, Typography } from 'antd';
import type { NetworkString } from 'ldk';
import React, { useEffect, useState } from 'react';

import { useTypedDispatch, useTypedSelector } from '../../app/store';
import { setNetwork } from '../settings/settingsSlice';

import { SendAndInsertTxForm } from './SendAndInsertTxForm';
import { TestingWalletsTable } from './TestingWalletsTable';
import type { TestingTowerIdName } from './testingSlice';
import { setAllKeys } from './testingSlice';

const { Title } = Typography;
const { Option } = Select;

export function TxsTesting(): JSX.Element {
  const dispatch = useTypedDispatch();
  const network = useTypedSelector(({ settings }) => settings.network);
  const [walletName, setWalletName] = useState<TestingTowerIdName>('alice');

  useEffect(() => {
    dispatch(setAllKeys());
  }, [dispatch, network]);

  const onNetworkChange = (n: NetworkString) => {
    dispatch(setNetwork(n));
  };

  return (
    <>
      <Title level={2}>Network</Title>
      <Row className="mb-12">
        <Col span={12}>
          <Select className="w-100" onChange={onNetworkChange} value={network}>
            {['regtest', 'testnet', 'liquid'].map((name) => (
              <Option key={name} value={name}>
                {name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <div className="mb-12">
        <TestingWalletsTable setWalletName={setWalletName} walletName={walletName} />
      </div>
      <div className="mb-12">
        <Title level={2}>Send And Insert Transaction</Title>
        <SendAndInsertTxForm walletName={walletName} />
      </div>
    </>
  );
}

import { notification } from 'antd';

import type {
  AdminAverageBalanceResponse,
  AdminUsageResponse,
  CalculateRewardDistributionResponse,
  GetInfoResponse,
  InsertCurrencyResponse,
  InsertRewardEraResponse,
  ListCurrenciesResponse,
  ListIdentitiesResponse,
  ListPaymentsResponse,
  ListRewardErasResponse,
  RemoveCurrencyResponse,
  RemoveRewardEraResponse,
  RevokeTokenResponse,
  RewardDistributionResponse,
} from '../api-spec/protobuf/gen/js/tower/v1/admin_pb';
import {
  AdminAverageBalanceRequest,
  AdminUsageRequest,
  CalculateRewardDistributionRequest,
  GetInfoRequest,
  InsertCurrencyRequest,
  InsertRewardEraRequest,
  ListCurrenciesRequest,
  ListIdentitiesRequest,
  ListPaymentsRequest,
  ListRewardErasRequest,
  RemoveCurrencyRequest,
  RemoveRewardEraRequest,
  RevokeTokenRequest,
  RewardDistributionRequest,
} from '../api-spec/protobuf/gen/js/tower/v1/admin_pb';
import type { RootState } from '../app/store';
import { selectAdminClient, selectMacaroon } from '../features/settings/settingsSlice';
import { retryRtkRequest } from '../utils';

import { towerApi } from './tower.api';

export const adminApi = towerApi.injectEndpoints({
  endpoints: (build) => ({
    insertRewardEra: build.mutation<InsertRewardEraResponse, InsertRewardEraRequest>({
      queryFn: async ({ rewardEra, rewardFund, timeframe }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.insertRewardEra(
            InsertRewardEraRequest.create({
              rewardEra,
              rewardFund,
              timeframe,
            }),
            { meta: { macaroon } }
          );
          notification.success({ message: 'Reward era inserted successfully' });
          return { data: call.response };
        });
      },
      invalidatesTags: ['rewardEra'],
    }),
    listRewardEras: build.query<ListRewardErasResponse['rewardEras'], void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.listRewardEras(ListRewardErasRequest.create(), { meta: { macaroon } });
          return { data: call.response.rewardEras };
        });
      },
      providesTags: ['rewardEra'],
    }),
    insertCurrency: build.mutation<InsertCurrencyResponse, InsertCurrencyRequest>({
      queryFn: async ({ currencySymbol, assetHash }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.insertCurrency(
            InsertCurrencyRequest.create({ currencySymbol, assetHash }),
            { meta: { macaroon } }
          );
          notification.success({ message: 'Currency inserted successfully' });
          return { data: call.response };
        });
      },
      invalidatesTags: ['currency'],
    }),
    removeCurrency: build.mutation<RemoveCurrencyResponse, RemoveCurrencyRequest>({
      queryFn: async ({ currencySymbol }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.removeCurrency(RemoveCurrencyRequest.create({ currencySymbol }), {
            meta: { macaroon },
          });
          notification.success({ message: 'Currency removed successfully' });
          return { data: call.response };
        });
      },
      invalidatesTags: ['currency'],
    }),
    listIdentities: build.query<ListIdentitiesResponse['identities'], void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.listIdentities(ListIdentitiesRequest.create(), { meta: { macaroon } });
          return { data: call.response.identities };
        });
      },
    }),
    listPayments: build.query<ListPaymentsResponse['payments'], ListPaymentsRequest>({
      queryFn: async ({ identity }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.listPayments(ListPaymentsRequest.create({ identity }), {
            meta: { macaroon },
          });
          return { data: call.response.payments };
        });
      },
    }),
    adminUsage: build.query<AdminUsageResponse['usage'], AdminUsageRequest>({
      queryFn: async ({ identity }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.adminUsage(AdminUsageRequest.create({ identity }), {
            meta: { macaroon },
          });
          return { data: call.response.usage };
        });
      },
    }),
    adminAverageBalance: build.query<
      AdminAverageBalanceResponse['averageBalance'],
      AdminAverageBalanceRequest
    >({
      queryFn: async ({ identity }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.adminAverageBalance(AdminAverageBalanceRequest.create({ identity }), {
            meta: { macaroon },
          });
          return { data: call.response.averageBalance };
        });
      },
    }),
    rewardDistribution: build.query<RewardDistributionResponse, RewardDistributionRequest>({
      queryFn: async ({ identity }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.rewardDistribution(RewardDistributionRequest.create({ identity }), {
            meta: { macaroon },
          });
          return { data: call.response };
        });
      },
      providesTags: ['rewardDistribution'],
    }),
    calculateRewardDistribution: build.mutation<
      CalculateRewardDistributionResponse,
      CalculateRewardDistributionRequest
    >({
      queryFn: async (_, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.calculateRewardDistribution(CalculateRewardDistributionRequest.create(), {
            meta: { macaroon },
          });
          notification.success({ message: `Reward distributions of unprocessed eras have been calculated` });
          return { data: call.response };
        });
      },
      invalidatesTags: ['rewardDistribution'],
    }),
    listCurrencies: build.query<ListCurrenciesResponse['currencyAssetPair'], void>({
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.listCurrencies(ListCurrenciesRequest.create(), { meta: { macaroon } });
          return { data: call.response.currencyAssetPair };
        });
      },
      providesTags: ['currency'],
    }),
    revokeToken: build.query<RevokeTokenResponse, RevokeTokenRequest>({
      queryFn: async ({ publicKey }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        return retryRtkRequest(async () => {
          const call = await client.revokeToken(RevokeTokenRequest.create({ publicKey }));
          return { data: call.response };
        });
      },
    }),
    removeRewardEra: build.mutation<RemoveRewardEraResponse, RemoveRewardEraRequest>({
      queryFn: async ({ rewardEra }, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.removeRewardEra(RemoveRewardEraRequest.create({ rewardEra }), {
            meta: { macaroon },
          });
          notification.success({ message: 'Reward era deleted successfully' });
          return { data: call.response };
        });
      },
      invalidatesTags: ['rewardEra'],
    }),
    getInfo: build.query<GetInfoResponse, void>({
      queryFn: async (_, { getState }) => {
        const state = getState() as RootState;
        const client = selectAdminClient(state);
        const macaroon = selectMacaroon(state);
        return retryRtkRequest(async () => {
          const call = await client.getInfo(GetInfoRequest.create(), {
            meta: { macaroon },
          });
          return { data: call.response };
        });
      },
    }),
  }),
});

export const {
  useInsertRewardEraMutation,
  useInsertCurrencyMutation,
  useRemoveCurrencyMutation,
  useListIdentitiesQuery,
  useListPaymentsQuery,
  useAdminUsageQuery,
  useAdminAverageBalanceQuery,
  useCalculateRewardDistributionMutation,
  useListCurrenciesQuery,
  useListRewardErasQuery,
  useRevokeTokenQuery,
  useRewardDistributionQuery,
  useRemoveRewardEraMutation,
  useGetInfoQuery,
} = adminApi;

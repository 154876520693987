import { Table } from 'antd';
import { useEffect, useState } from 'react';

import { useRewardDistributionQuery } from '../../apis/admin.api';

interface RewardDistributionTableProps {
  state: { rewardEraId: number; pubkey: string };
}

export function RewardDistributionTable({ state }: RewardDistributionTableProps): JSX.Element {
  const REWARD_DISTRIBUTION_PAGE_SIZE = 5;
  const [rewardDistributionFormatted, setRewardDistributionFormatted] = useState<
    {
      rewardDistributionCurrencies: JSX.Element[];
      rewardDistributionAmounts: JSX.Element[];
      lowRankedBalances: JSX.Element[];
      qualifiedBalances: JSX.Element[] | string;
      turnovers: JSX.Element[];
      turnoverThreshold: number;
    }[]
  >([]);
  const { data: rewardDistribution } = useRewardDistributionQuery({
    identity: {
      rewardEra: state.rewardEraId,
      publicKey: state.pubkey,
    },
  });

  useEffect(() => {
    if (Object.keys(rewardDistribution || {}).length > 0) {
      //
      const rewardDistributionCurrencies = Object.keys(rewardDistribution?.rewardDistribution || {})
        .sort()
        .map((currency, index) => <div key={`${index}${currency}`}>{currency}</div>);
      //
      const rewardDistributionAmounts = Object.entries(rewardDistribution?.rewardDistribution || {})
        .sort(([aCurrency], [bCurrency]) => {
          if (aCurrency > bCurrency) {
            return 1;
          }
          if (aCurrency < bCurrency) {
            return -1;
          }
          return 0;
        })
        .map(([, amount], index) => <div key={`${index}${amount}`}>{amount}</div>);
      //
      const lowRankedBalances = Object.entries(rewardDistribution?.lowRankedBalance || {})
        .sort(([aCurrency], [bCurrency]) => {
          if (aCurrency > bCurrency) {
            return 1;
          }
          if (aCurrency < bCurrency) {
            return -1;
          }
          return 0;
        })
        .map(([, lowRankedBalanceInfo], index) => (
          <div key={`${index}${lowRankedBalanceInfo}`}>
            {lowRankedBalanceInfo.balance} - ({lowRankedBalanceInfo.percentage}%)
          </div>
        ));
      //
      const qualifiedBalances = Object.entries(rewardDistribution?.qualifiedBalance || {})
        .sort(([aCurrency], [bCurrency]) => {
          if (aCurrency > bCurrency) {
            return 1;
          }
          if (aCurrency < bCurrency) {
            return -1;
          }
          return 0;
        })
        .map(([, qualifiedBalanceInfo], index) => (
          <div key={`${index}${qualifiedBalanceInfo}`}>
            {qualifiedBalanceInfo.balance} - ({qualifiedBalanceInfo.percentage}%)
          </div>
        ));
      //
      const turnovers = Object.entries(rewardDistribution?.turnover || {})
        .sort(([aCurrency], [bCurrency]) => {
          if (aCurrency > bCurrency) {
            return 1;
          }
          if (aCurrency < bCurrency) {
            return -1;
          }
          return 0;
        })
        .map(([, amount], index) => <div key={`${index}${amount}`}>{amount}</div>);

      setRewardDistributionFormatted([
        {
          rewardDistributionCurrencies,
          rewardDistributionAmounts,
          lowRankedBalances,
          qualifiedBalances: qualifiedBalances.length ? qualifiedBalances : 'N/A',
          turnovers,
          turnoverThreshold: rewardDistribution?.turnoverThreshold || 0,
        },
      ]);
    }
  }, [rewardDistribution]);

  return (
    <Table
      rowKey="turnoverThreshold"
      dataSource={rewardDistributionFormatted}
      columns={[
        {
          title: 'Currency',
          dataIndex: 'rewardDistributionCurrencies',
          key: 'rewardDistributionCurrencies',
        },
        {
          title: 'Reward Distribution Amount',
          dataIndex: 'rewardDistributionAmounts',
          key: 'rewardDistributionAmounts',
        },
        {
          title: 'Low Ranked Balance',
          dataIndex: 'lowRankedBalances',
          key: 'lowRankedBalances',
        },
        {
          title: 'Qualified Balance',
          dataIndex: 'qualifiedBalances',
          key: 'qualifiedBalances',
        },
        {
          title: 'Turnover Amount',
          dataIndex: 'turnovers',
          key: 'turnovers',
        },
        {
          title: 'Turnover Threshold',
          dataIndex: 'turnoverThreshold',
          key: 'turnoverThreshold',
        },
      ]}
      pagination={
        rewardDistributionFormatted.length > REWARD_DISTRIBUTION_PAGE_SIZE && {
          total: rewardDistributionFormatted.length,
          pageSize: REWARD_DISTRIBUTION_PAGE_SIZE,
        }
      }
    />
  );
}

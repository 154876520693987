import './sideMenu.scss';

import {
  ExperimentOutlined,
  HistoryOutlined,
  IdcardOutlined,
  LogoutOutlined,
  PayCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { useListCurrenciesQuery } from '../../apis/admin.api';
import { useTypedDispatch, useTypedSelector } from '../../app/store';
import { logout } from '../../features/settings/settingsSlice';
import {
  CURRENCY_ROUTE,
  IDENTITY_PUBLIC_KEYS_ROUTE,
  REWARD_ERA_ROUTE,
  SETTINGS_ROUTE,
  TESTING_ROUTE,
} from '../../routes/constants';

const { Sider } = Layout;

export function SideMenu(): JSX.Element {
  const dispatch = useTypedDispatch();
  const isAdvancedMode = useTypedSelector(({ settings }) => settings.isAdvancedMode);
  const location = useLocation();
  const navigate = useNavigate();
  const { data: currencies } = useListCurrenciesQuery(undefined, { refetchOnMountOrArgChange: true });

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const onMenuClick: MenuProps['onClick'] = (ev) => {
    if (ev.key === '/logout') {
      dispatch(logout());
    } else {
      navigate(ev.key);
    }
  };

  const items: MenuProps['items'] = [
    getItem('Currency', CURRENCY_ROUTE, <PayCircleOutlined />),
    getItem('Identity', IDENTITY_PUBLIC_KEYS_ROUTE, <IdcardOutlined />),
    getItem('Settings', SETTINGS_ROUTE, <SettingOutlined />),
    getItem('Log Out', '/logout', <LogoutOutlined />),
  ];
  if (Object.keys(currencies || {}).length > 0) {
    items.splice(2, 0, getItem('Reward Era', REWARD_ERA_ROUTE, <HistoryOutlined />));
  }
  if (isAdvancedMode) {
    items.splice(-2, 0, getItem('Testing', TESTING_ROUTE, <ExperimentOutlined />));
  }

  return (
    <Sider className="sidebar" theme="dark">
      <div className="logo" />
      <Menu
        onClick={onMenuClick}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['currency']}
        selectedKeys={[location.pathname]}
        items={items}
      />
    </Sider>
  );
}

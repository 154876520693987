import './daemonFormItems.scss';

import { UploadOutlined } from '@ant-design/icons';
import type { FormInstance } from 'antd';
import { Button, Col, Form, Input, Row } from 'antd';
import { fileOpen } from 'browser-fs-access';
import classNames from 'classnames';
import React from 'react';

import { useTypedSelector } from '../../app/store';
import { selectMacaroon } from '../../features/settings/settingsSlice';
import { buf2hex } from '../../utils';

const { TextArea } = Input;

export interface DaemonFormValues {
  towerDaemonEndpoint: string;
  macaroon: string;
}

interface DaemonFormItemsProps {
  disabled?: boolean;
  hideUpload?: boolean;
  form: FormInstance<DaemonFormValues>;
  isMacaroonHidden: boolean;
  setIsMacaroonHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DaemonFormItems({
  disabled = false,
  hideUpload = false,
  form,
  isMacaroonHidden,
  setIsMacaroonHidden,
}: DaemonFormItemsProps): JSX.Element {
  const macaroon = useTypedSelector(selectMacaroon);

  return (
    <>
      <Form.Item
        label="Tower Daemon Endpoint"
        name="towerDaemonEndpoint"
        rules={[{ required: true }, { type: 'url', message: 'Please enter a valid url' }]}
      >
        <Input type="url" placeholder="https://tower.equitas.foundation" disabled={disabled} />
      </Form.Item>
      <>
        <div className="ant-col ant-form-item-label">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="ant-form-item-required">Macaroon</label>
        </div>
        <div className="macaroon-container" onClick={() => setIsMacaroonHidden(false)}>
          <span
            className={classNames({
              'reveal-text': isMacaroonHidden,
              'reveal-text__hidden': !isMacaroonHidden,
            })}
          >
            Click to reveal
          </span>
          <div className={classNames({ 'macaroon-hidden': isMacaroonHidden })}>
            <Form.Item name="macaroon" rules={[{ required: true }]}>
              <TextArea id="macaroonTextArea" rows={4} disabled={disabled} placeholder={macaroon} />
            </Form.Item>
          </div>
        </div>

        <Row justify="start" className={classNames({ 'hide-upload': hideUpload })}>
          <Col>
            <Button
              onClick={async () => {
                try {
                  const file = await fileOpen({
                    description: 'Macaroon file',
                    extensions: ['.macaroon'],
                  });
                  const arrBuf = await file.arrayBuffer();
                  const hex = buf2hex(arrBuf);
                  form.setFieldsValue({ macaroon: hex });
                  setIsMacaroonHidden(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              icon={<UploadOutlined />}
            >
              Upload
            </Button>
          </Col>
        </Row>
      </>
    </>
  );
}
